import axiosApiInstance from '@/api/api'

export const getBrands = async (
  searchText: string,
  sorting: string,
  filters: string[] | undefined
) => {
  try {
    return await axiosApiInstance.post(`/brand/brands`, {
      search_text: searchText,
      sorting: sorting,
      filters: filters?.length ? filters : undefined
    })
  } catch (err) {
    throw err
  }
}

export const getBrandsCategories = async () => {
  try {
    return await axiosApiInstance.get(`/brand/brand-categories`)
  } catch (err) {
    throw err
  }
}

export const getPopularBrands = async (payload) => {
  try {
    return await axiosApiInstance.get(`/brand/popular`, { params: payload })
  } catch (err) {
    throw err
  }
}

export const getBrandInfo = async (brandId) => {
  try {
    return await axiosApiInstance.get(`/brand/brand/${brandId}`)
  } catch (err) {
    throw err
  }
}

export const subscribeToBrandUpdates = async (brandId) => {
  try {
    return await axiosApiInstance.get(`/brand/track-brand-updates/${brandId}`)
  } catch (err) {
    throw err
  }
}
