<script setup lang="ts">
import { Autoplay, Controller, FreeMode, Navigation, Virtual } from 'swiper/modules'
import { onMounted, ref } from 'vue'
import 'swiper/css'
import 'swiper/css/free-mode'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue'
import Control from '@/components/UI/controllSwiper/control.vue'
import { getPopularBrands } from '@/api/brands'

const swiperModules = [FreeMode, Autoplay, Navigation, Controller, Virtual]
const companies = ref()

onMounted(async () => {
  await getPopularBrands({
    page_num: 1,
    page_size: 16
  }).then((res) => {
    companies.value = res.data.brands
  })
})
</script>

<template>
  <div class="popular-brands__wrapper">
    <div class="swp-wrp">
      <swiper
        :free-mode="true"
        :space-between="20"
        :slides-per-view="8.5"
        :slides-offset-after="20"
        :breakpoints="{
          480: {
            slidesPerView: 5.5
          },
          1366: {
            slidesPerView: 6
          },
          1440: {
            slidesPerView: 6.5
          },
          1920: {
            slidesPerView: 8.5
          }
        }"
        :modules="swiperModules"
        class="company-carousel__container"
      >
        <template #container-start>
          <div class="popular-brands__header">
            <span class="popular-brands__title">Исследуй новинки любимых брендов</span>
            <control :control-color="'rgba(225, 213, 191, 1)'" />
          </div>
        </template>
        <swiper-slide
          v-for="(company, index) in companies"
          :key="index"
          class="company-carousel__item"
        >
          <div
            v-if="company?.background"
            class="adv-images__wrapper"
          >
            <img
              class="adv-images"
              :src="company.background"
            >
            <div class="logo-img">
              <img
                class="logo-brand"
                :src="company.logo_url"
              >
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<style scoped>
:deep(.popular-brands__header) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 220px 60px 0;
}

.popular-brands__title {
  font-size: 48px;
  line-height: 48px;
  line-height: 48px;
  color: rgba(225, 213, 191, 1);
}

.popular-brands__wrapper {
  display: flex;
  padding-left: 100px;
  flex-direction: column;
  min-height: 670px;
  width: 100%;
  justify-content: center;
  background: rgba(26, 33, 51, 1);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}

.adv-images__wrapper:hover .adv-images {
  transform: scale(1.2);
  transition: transform 0.4s ease-in-out;
}

.adv-images__wrapper {
  border-radius: 40px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: relative;
}

.logo-brand {
  width: 100%;
}

.adv-images {
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease-in-out;
}

.logo-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 35%;
  position: absolute;
}
</style>

<style>
.company-carousel__item {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 40px;
  text-align: center;
  width: auto;
  height: 240px;
  overflow: hidden;
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
</style>
