<script setup lang="ts">
import MyMultiSelect from '@/blocks/MultiSelect/MyMultiSelect.vue'
import Accordion from 'primevue/accordion'
import Badge from 'primevue/badge'
import AccordionPanel from 'primevue/accordionpanel'
import AccordionHeader from 'primevue/accordionheader'
import AccordionContent from 'primevue/accordioncontent'
import { useSearch } from '@/stores/search'
import TempChart from '@/blocks/Chart/TempChart.vue'
import ToggleSwitch from 'primevue/toggleswitch'
import { getCurrentInstance } from 'vue'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Button from 'primevue/button'

const visible = defineModel()
const instance = getCurrentInstance()

const search = useSearch()
const route = useRoute()
const router = useRouter()

const definedFilters = computed(() => search.responseDefineFilters)
const searchedText = computed(() => route.params.searchText)
const totalQuantity = computed(() => search.totalQuantity)
const currentFilters = computed(() => search.currentFilters)

const checked = ref()

const staticFilters = ref([
  {
    name: 'С рейтингом выше 4.5',
    icon: 'pi-star-fill',
    tooltip: undefined
  },
  {
    name: 'Можно купить через байера',
    icon: undefined,
    tooltip: undefined
  },
  {
    name: 'проверенный товар',
    icon: 'pi-question-circle',
    tooltip: 'Объяснение что такое проверенный товар'
  }
])


async function clearAllFilter() {
  await search.clearAllFilters()
}

function clearSelectedFilter(data) {
  search.clearFiltersToSelectedFilterType(data)
}



</script>

<template>
  <div class="filters-sidebar__wrapper">
    <div class="filters-sidebar__inner">
      <Accordion value="0">
        <AccordionPanel
          v-for="(categoryItem, index) in currentFilters"
          :value="index.toString()"
          :key="categoryItem.options.label + index + `${categoryItem.options.values?.filter((item) => item.isSelected).length}`"
          :pt="{
          root:{
            style:{
              borderStyle: 'none',
            }
          }
        }"
        >
          <AccordionHeader
            v-if="categoryItem.type === 'GRAPH_SELECT' || categoryItem.options?.values?.length"
            :pt="{
            root:{
              style:{
                color:'rgba(61, 61, 61, 1)',
                padding: '12px 16px'
              }
            }
          }"
          >
            <div class="accordion-header__inner">
              <div class="multiselect-inner-accordion-header-wrapper">
                {{ categoryItem.options.label.toLocaleUpperCase() }}
              </div>
              <div class="right-sight-inner-accordion-item" v-if="categoryItem.options.values?.filter((item) => item.isSelected).length">
                <Badge
                       :value="categoryItem.options.values?.filter((item) => item.isSelected).length"
                       :pt="{
                    root:{
                      style:{
                        background:'rgba(225, 213, 191, 1)',
                        color:'rgba(115, 99, 84, 1)',
                        fontWeight: '700',
                        fontSize: '12px',
                        minWidth:'20px',
                        height:'20px',
                      }
                    }
                   }"
                ></Badge>
                <Button
                  label="Сбросить"
                  @click.stop="clearSelectedFilter(categoryItem.options)"
                  text
                  :pt="{
                  root:{
                    style:{
                      padding:'0',
                      color:'rgba(145, 145, 145, 1)',
                    }
                  }
                }"
                  severity="secondary"
                />
              </div>
            </div>
          </AccordionHeader>
          <AccordionContent>
            <MyMultiSelect
              v-if="
              (categoryItem.type === 'MULTI_SELECT' || 'SINGLE_SELECT') &&
                categoryItem.options?.values?.length
            "
              @clear-selected-filter="clearSelectedFilter"
              :category-type="categoryItem.type"
              :data="categoryItem.options"
              :filter-type="categoryItem.filter_type"
            />
            <TempChart
              v-if="categoryItem.type === 'GRAPH_SELECT'"
              :graph-data="categoryItem"
              :index-of-category="index"
            />
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
      <div class="constant-filters-toggles-list">
        <div
          v-for="staticFiltersItem in staticFilters"
          class="toggle-item"
        >
        <span class="font">
          {{ staticFiltersItem.name.toLocaleUpperCase() }}
          <i
            v-if="staticFiltersItem.icon"
            v-tooltip="staticFiltersItem.tooltip"
            class="pi"
            :class="staticFiltersItem.icon"
          />
        </span>
          <ToggleSwitch :disabled="true" v-model="checked" />
        </div>
      </div>
    </div>
    <div class="bottom-buttons__wrapper">
      <div class="total-items-label">
        <span> мы нашли {{ totalQuantity }} товаров </span>
      </div>
      <div class="multiselect-inner-bottom-buttons">
        <Button class="btn-height-fix"
                @click="clearAllFilter"
                label="Сбросить" severity="help"
        />
        <Button
          class="btn-height-fix"
          @click="visible = false"
          label="Готово"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

.filters-sidebar__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.toggle-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px;
  color: rgba(61, 61, 61, 1);
  font-size: 16px;
}

.font {
  font-weight: 600;
}

.filters-sidebar__inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

:deep(.p-toggleswitch.p-toggleswitch-checked .p-toggleswitch-slider:before) {
  transform: scale(1.2);
}

.multiselect-inner-bottom-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 0 0;
  gap: 8px;
}

.bottom-buttons__wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 40px;
}

.btn-height-fix {
  height: 40px;
  width: 50%;
}

.total-items-label {
  display: flex;
  justify-content: center;
  color: rgba(61, 61, 61, 1);
  font-size: 16px;
}

.multiselect-inner-accordion-header-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.accordion-header__inner {
  display: flex;
  width: 90%;
  justify-content: space-between;
  gap:8px;
}

.right-sight-inner-accordion-item {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

</style>
