import { ref } from 'vue'
import { defineStore } from 'pinia'
import axiosApiInstance from '../api/api'
import { useAuthModal } from '@/stores/authModal'

export const useAuthStore = defineStore('auth', () => {
  const userInfo = ref({
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
    refresh_expires_in: null,
    name: null,
    email: null,
    tempEmailFormPasswordRestore: null
  })

  userInfo.value.accessToken = JSON.parse(localStorage.getItem('userTokens'))?.accessToken
  userInfo.value.refreshToken = JSON.parse(localStorage.getItem('userTokens'))?.refreshToken
  userInfo.value.expiresIn = JSON.parse(localStorage.getItem('userTokens'))?.expiresIn
  userInfo.value.refresh_expires_in = JSON.parse(
    localStorage.getItem('userTokens')
  )?.refresh_expires_in

  const error = ref('')
  const loader = ref(false)

  const getUserInfo = async () => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.get(`/auth/user-info`, {
        headers: {
          Authorization: `Bearer ${userInfo.value.accessToken}`
        }
      })
      if (response) {
        userInfo.value.name = response.data.name
        userInfo.value.email = response.data.email
      }
    } catch (err) {
      throw error.value
    } finally {
      loader.value = false
    }
  }
  const auth = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.post(`/auth/login`, {
        ...payload
      })
      userInfo.value = {
        accessToken: response.data.access_token,
        expiresIn: response.data.expires_in * 1000 + Date.now(),
        refresh_expires_in: response.data.refresh_expires_in * 1000 + Date.now(),
        refreshToken: response.data.refresh_token
      }
      localStorage.setItem(
        'userTokens',
        JSON.stringify({
          accessToken: response.data.access_token,
          expiresIn: response.data.expires_in * 1000 + Date.now(),
          refresh_expires_in: response.data.refresh_expires_in * 1000 + Date.now(),
          refreshToken: response.data.refresh_token
        })
      )

      await getUserInfo()
    } catch (err) {
      throw error.value
    } finally {
      loader.value = false
    }
  }

  const thirdPartyAuth = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.get(`/auth/login-with-third-party/${payload}`)
      if (response) {
        window.open(response.data.auth_uri)
      }
    } catch (err) {
      throw error.value
    } finally {
      loader.value = false
    }
  }

  const exchangeCodeForTokens = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.get(`/auth/exchange-code-for-tokens/?code=${payload}`)
      if (response) {
        if (response) {
          userInfo.value = {
            accessToken: response.data.access_token,
            expiresIn: response.data.expires_in,
            refresh_expires_in: response.data.refresh_expires_in,
            refreshToken: response.data.refresh_token
          }
          localStorage.setItem(
            'userTokens',
            JSON.stringify({
              accessToken: response.data.access_token,
              expiresIn: response.data.expires_in * 1000 + Date.now(),
              refresh_expires_in: response.data.refresh_expires_in * 1000 + Date.now(),
              refreshToken: response.data.refresh_token
            })
          )
        }

        await getUserInfo()
      }
    } catch (err) {
      throw error.value
    } finally {
      loader.value = false
    }
  }

  const forgotPassword = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.post(`/auth/forgot-password`, {
        email: payload
      })
      if (response) {
        userInfo.value.tempEmailFormPasswordRestore = payload
        useAuthModal().showAuthModal('checkEmailResetPassword')
      }
    } catch (err) {
      throw error.value
    } finally {
      loader.value = false
    }
  }

  const signUp = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.post(`/auth/signup`, {
        ...payload
      })
      userInfo.value = {
        accessToken: response.data.access_token,
        expiresIn: response.data.expires_in,
        refresh_expires_in: response.data.refresh_expires_in,
        refreshToken: response.data.refresh_token
      }
      localStorage.setItem(
        'userTokens',
        JSON.stringify({
          accessToken: response.data.access_token,
          expiresIn: response.data.expires_in * 1000 + Date.now(),
          refresh_expires_in: response.data.refresh_expires_in * 1000 + Date.now(),
          refreshToken: response.data.refresh_token
        })
      )

      useAuthModal().showAuthModal('verifyUser')
    } catch (err) {
      throw err
    } finally {
      loader.value = false
    }
  }

  const verifyUser = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.get(`/auth/verify-email?token=${payload}`)
      if (response) {
        useAuthModal().showAuthModal('EndOfRegistration')
      }
    } catch (err) {
      throw err
    } finally {
      loader.value = false
    }
  }

  const resetPassword = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.post(`/auth/reset-password`, {
        token: payload.token,
        password: payload.password
      })

      if (response) {
        userInfo.value = {
          accessToken: response.data.access_token,
          expiresIn: response.data.expires_in,
          refresh_expires_in: response.data.refresh_expires_in,
          refreshToken: response.data.refresh_token
        }
        localStorage.setItem(
          'userTokens',
          JSON.stringify({
            accessToken: response.data.access_token,
            expiresIn: response.data.expires_in * 1000 + Date.now(),
            refresh_expires_in: response.data.refresh_expires_in * 1000 + Date.now(),
            refreshToken: response.data.refresh_token
          })
        )

        await getUserInfo()
      }
    } catch (err) {
      throw err
    } finally {
      loader.value = false
    }
  }

  const logout = () => {
    localStorage.removeItem('userTokens')
    userInfo.value = {
      accessToken: null,
      email: null,
      name: null,
      refreshToken: null,
      expiresIn: null,
      refresh_expires_in: null
    }
  }

  return {
    auth,
    userInfo,
    error,
    loader,
    logout,
    signUp,
    forgotPassword,
    verifyUser,
    resetPassword,
    thirdPartyAuth,
    exchangeCodeForTokens,
    getUserInfo
  }
})
