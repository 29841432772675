<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import Dialog from 'primevue/dialog'
import { useAuthModal } from '@/stores/authModal'

const auth = useAuthModal()

const authModalState = computed(() => auth.authModal.isShow)
const authModalCurrentView = computed(() => auth.authModal.currentView)
const visible = ref(false)

function handleHideAuthModal() {
  useAuthModal().hideAuthModal()
}

watch(
  () => authModalState.value,
  () => {
    visible.value = authModalState.value
  }
)
</script>

<template>
  <Dialog
    v-model:visible="visible"
    modal
    :style="{
      width: 'fit-content',
      height: 'fit-content',
      background: 'rgba(245, 245, 247, 1)',
      borderRadius: '28px',
      overflow: 'hidden'
    }"
    @hide="handleHideAuthModal"
  >
    <template #container="{ closeCallback }">
      <div class="auth_wrapper_header">
        <div class="auth_wrapper_header__logo">
          <svg
            width="108"
            height="97"
            viewBox="0 0 108 97"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M59.6308 59.3136L55.9981 54.0015H35.5605L31.9617 59.3136H26.9712L43.0632 36.0898H48.5291L64.6552 59.3136H59.6308ZM37.5974 51.0049H53.9611L45.7793 38.9843L37.5974 51.0049Z"
              fill="#24314A"
            />
            <path
              d="M71.154 59.3136H67.0121V36.0898H71.154V59.3136Z"
              fill="#24314A"
            />
            <path
              d="M80.1055 59.3136H75.9636V36.0898H98.2013C103.871 36.0898 107.095 38.2692 107.095 43.6835C107.095 48.5871 104.38 51.0049 99.016 51.2092L96.4357 51.2432L98.5059 52.6734L106.621 59.3136H101.324L91.8182 51.4135H80.1055V59.3136ZM80.1055 39.6653V48.2125H97.9289C101.425 48.2125 102.852 46.9867 102.852 43.956C102.852 40.8572 101.425 39.6653 97.9289 39.6653H80.1055Z"
              fill="#24314A"
            />
            <path
              d="M4.14191 59.3136H0V36.0898H29.9776V39.6653H4.14191V46.9186H29.3325V50.2557H4.14191V59.3136Z"
              fill="#24314A"
            />
          </svg>
        </div>
        <div
          class="auth-wrapper-close-modal"
          @click="closeCallback"
        >
          <i
            class="pi pi-times"
            style="font-size: 14px"
          />
        </div>
      </div>
      <component
        :is="authModalCurrentView"
        @close-modal="closeCallback"
      />
    </template>
  </Dialog>
</template>

<style scoped>
.auth_wrapper_header__logo {
  display: flex;
  justify-self: center;
}

.auth_wrapper_header {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  width: 60%;
}

.auth-wrapper-close-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 48px;
  height: 48px;
}
</style>
