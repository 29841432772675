type Filter = {
  filter_type: string;
  values: string[] | { lte: number; gte: number };
};

export function transformFiltersToObjectQuery(filtersArray: Filter[] ): Record<string, any> {
  const result: Record<string, any> = {};


  filtersArray.forEach(filter => {
    if(filter.filter_type === 'price') {
      result[filter.filter_type] = [filter.values.gte,filter.values.lte]
    } else {
      result[filter.filter_type] = filter.values;
    }
  });

  return result;
}