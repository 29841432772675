<script setup lang="ts"></script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.4495 4.194C3.5925 2.9385 5.2245 2.25 7.14 2.25C8.886 2.25 10.7505 3.075 12 4.6875C13.2405 3.075 15.0975 2.25 16.86 2.25C18.771 2.25 20.4 2.9355 21.546 4.188C22.68 5.43 23.25 7.131 23.25 9.0195C23.25 12.267 21.5805 14.8815 19.467 16.914C17.3595 18.942 14.712 20.4885 12.5175 21.624C12.3568 21.7071 12.1783 21.7503 11.9974 21.7498C11.8164 21.7492 11.6383 21.7051 11.478 21.621C9.2835 20.4735 6.636 18.939 4.5285 16.9215C2.415 14.895 0.75 12.2895 0.75 9.0195C0.75 7.137 1.317 5.436 2.4495 4.194ZM4.113 5.709C3.4305 6.459 3 7.5795 3 9.0195C3 11.4555 4.224 13.5135 6.0855 15.2955C7.8045 16.944 9.9855 18.273 12.003 19.353C14.0055 18.288 16.188 16.947 17.9085 15.2925C19.773 13.4985 21 11.43 21 9.0195C21 7.575 20.568 6.4545 19.884 5.7045C19.212 4.971 18.21 4.5 16.86 4.5C15.396 4.5 13.7205 5.4405 13.0695 7.422C12.9959 7.64763 12.8528 7.84419 12.6607 7.98357C12.4686 8.12295 12.2373 8.19801 12 8.19801C11.7627 8.19801 11.5314 8.12295 11.3393 7.98357C11.1472 7.84419 11.0041 7.64763 10.9305 7.422C10.281 5.442 8.5875 4.5 7.14 4.5C5.7855 4.5 4.785 4.971 4.113 5.709Z"
      fill="white"
    />
  </svg>
</template>

<style scoped></style>
