<script setup lang="ts"></script>

<template>
  <div class="arrow_wrapper">
    <div class="arrow-img__wrapper">
      <svg
        width="227"
        height="69"
        viewBox="0 0 227 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M225.573 67.9987C226.124 67.9586 226.538 67.4795 226.497 66.9287C226.457 66.3778 225.978 65.9639 225.427 66.004L225.573 67.9987ZM132 17.0009L132.08 17.9977L132 17.0009ZM61 54.5005L60.8451 55.4885L61 54.5005ZM7.57669 0.684143C7.12549 0.365654 6.50153 0.473228 6.18303 0.924431L0.992889 8.27714C0.674393 8.72834 0.781967 9.3523 1.23317 9.67079C1.68437 9.98928 2.30832 9.88171 2.62682 9.43051L7.24028 2.89477L13.776 7.50823C14.2272 7.82673 14.8512 7.71914 15.1697 7.26794C15.4882 6.81675 15.3806 6.19279 14.9294 5.87429L7.57669 0.684143ZM225.427 66.004C177.587 69.4917 139.329 58.2247 120.626 45.6115C115.954 42.4609 112.554 39.261 110.519 36.2355C108.484 33.2094 107.877 30.4627 108.552 28.1157C109.227 25.7653 111.267 23.5392 115.136 21.732C119.003 19.926 124.582 18.5976 132.08 17.9977L131.92 16.0041C124.296 16.6142 118.455 17.9745 114.29 19.9199C110.127 21.8643 107.524 24.4524 106.629 27.5632C105.734 30.6774 106.631 34.0387 108.86 37.3517C111.088 40.6652 114.711 44.0352 119.507 47.2696C138.676 60.1964 177.413 71.5097 225.573 67.9987L225.427 66.004ZM132.08 17.9977C139.83 17.3776 145.918 17.7242 150.468 18.7469C155.035 19.7732 157.947 21.455 159.498 23.4109C161.007 25.3149 161.326 27.6025 160.445 30.1666C159.548 32.7749 157.402 35.6614 153.98 38.5487C140.276 50.1115 107.231 60.7354 61.1549 53.5126L60.8451 55.4885C107.269 62.7657 140.974 52.1394 155.27 40.0773C158.848 37.0583 161.28 33.8901 162.336 30.8168C163.408 27.6993 163.055 24.6784 161.065 22.1683C159.116 19.7103 155.7 17.8727 150.907 16.7955C146.098 15.7148 139.795 15.374 131.92 16.0041L132.08 17.9977ZM61.1549 53.5126C51.5504 52.007 39.4706 46.1136 29.1638 36.9717C18.8658 27.8375 10.4367 15.5484 7.98546 1.33121L6.01454 1.67102C8.56329 16.4538 17.3012 29.1231 27.8367 38.4679C38.3634 47.8049 50.7836 53.9113 60.8451 55.4885L61.1549 53.5126Z"
          fill="white"
        />
      </svg>
    </div>
    <span class="arrow-subtitle">Попробуй ;)</span>
  </div>
</template>

<style scoped>
.arrow_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 6px;
  margin-top: 80px;
  margin-right: -200px;
}

.arrow-img__wrapper svg {
  width: 100%;
  height: 100%;
}

.arrow-subtitle {
  font-size: 18px;
  color: rgba(163, 163, 163, 1);
}
@media (max-width: 1919px) {
  .arrow_wrapper {
    margin-top: 60px;
    margin-right: -100px;
  }

  .arrow-img__wrapper svg {
    width: 123px;
    height: 66px;
  }
}

@media (max-width: 1024px) {
  .arrow_wrapper {
    margin-top: 70px;
    margin-right: -40px;
  }

  .arrow-img__wrapper svg {
    width: 98px;
    height: 36px;
  }
}
</style>
