import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useImageSearch = defineStore('imageSearch', () => {

  const imageBase64Data = ref()
  const imageFile = ref()
  const currentImageLink = ref()


  return {
    imageBase64Data,
    imageFile,
    currentImageLink
  }
})
