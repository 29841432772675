<script setup lang="ts">
import Paginator from 'primevue/paginator'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import type { PageInfo } from '@/types/searchItemsTypes'
import { useSearch } from '@/stores/search'
import Button from 'primevue/button'


const emit = defineEmits<{
  (e: 'load-more'): void
}>()

const router = useRouter()
const route = useRoute()
const search = useSearch()

const first = ref(0)
const pagination = ref(null)


const pageInfo = computed(() => search.pageInfo)
const queryPage = computed(() => route.query.page)

const isStubAfterFirstPageVisible = ref('...')
const isStubBeforeLastPageVisible = computed(() => '...' )

const isLinkToFirstPageVisible = computed(() => pagination.value?.currentPage > 3)
const isLinkToLastPageVisible = computed(
  () => pagination.value?.currentPage < pageInfo.value.total_pages - 3
)

function loadMore() {
  emit('load-more')
}

async function pageHandler(page) {
  await search.setItemsBehavior('default')
  await router.push({
    query: {
      ...route.query,
      page: page.page + 1
    }
  })
}



watch(
  queryPage,
  () => {
    first.value = (Number(queryPage.value) - 1) * pageInfo.value.page_size
  },
  {
    deep: true
  }
)

watch(pageInfo,() => {
  if (queryPage.value && pageInfo.value.page_size) {
    first.value = (Number(queryPage.value) - 1) * pageInfo.value.page_size
  }
})

onMounted(() => {
  if (queryPage.value && pageInfo.value.page_size) {
    first.value = (Number(queryPage.value) - 1) * pageInfo.value.page_size
  }
})
</script>

<template>
  <div class="search-page-pagination-inner">
    <Paginator
      v-if="pageInfo && (pageInfo?.total_pages !== 1)"
      ref="pagination"
      :page-link-size="3"
      v-model:first="first"
      :pt="{
        contentStart: {
          style: {
            minWidth: 'fit-content'
          }
        },
        first: {
          style: {
            minWidth: 'fit-content'
          }
        },
        last: {
          style: {
            minWidth: 'fit-content'
          }
        }
      }"
      @page="pageHandler"
      :template="{
        default: 'PrevPageLink FirstPageLink PageLinks LastPageLink NextPageLink '
      }"
      :rows="pageInfo.page_size"
      :totalRecords="pageInfo.page_size * pageInfo.total_pages"
    >
      <template #prevpagelinkicon>
        <i class="pi pi-arrow-left" />
      </template>
      <template #firstpagelinkicon>
        <div v-if="isLinkToFirstPageVisible" class="links-pag-width">
          1
        </div>
      </template>

      <template #lastpagelinkicon>
        <div v-if="isLinkToLastPageVisible" class="links-pag-width">
          {{ pageInfo.total_pages }}
        </div>
      </template>
      <template #nextpagelinkicon>
        <i class="pi pi-arrow-right" />
      </template>
    </Paginator>
    <Button
      v-if="pageInfo && (pageInfo?.total_pages !== 1) && (pageInfo?.page_num !== pageInfo?.total_pages)"
      class="full-width load-more"
      @click="loadMore"
      :dt="{
        root: {
          paddingX: '16px',
          paddingY: '20px',
          borderRadius: '8px'
        },
        colorScheme: {
          light: {
            contrast: {
              background: 'rgba(162, 184, 233, 0.4)',
              hoverBackground: 'rgba(162, 184, 233, 0.6)',
              activeBackground: 'rgba(162, 184, 233, 0.7)',
              borderColor: 'rgba(162, 184, 233, 0.4)',
              hoverBorderColor: 'rgba(162, 184, 233, 0.6)',
              activeBorderColor: 'rgba(162, 184, 233, 0.7)',
              color: 'rgba(61, 61, 61, 1)'
            }
          }
        }
      }"
      label="Загрузить ещё"
      severity="contrast"
    />
  </div>
</template>

<style scoped>
.search-page-pagination-inner {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 40px;
}

.links-pag-width {
  width: 44px;
}

.dots {
  font-weight: 800;
  color: rgba(36, 49, 74, 1);
}

:deep(.p-paginator-content:has(.p-paginator-first:not(.p-disabled) .links-pag-width) .p-paginator-pages::before) {
  content: '...';
}

:deep(.p-paginator-content:has(.p-paginator-last:not(.p-disabled) .links-pag-width)  .p-paginator-pages::after) {
  content: '...';
}
</style>
