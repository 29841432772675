import axiosApiInstance from '@/api/api'

export async function getRecommendationsBanners(audience) {
  try {
    return await axiosApiInstance.get(`collections/recommendations?audience=${audience}`)
  } catch (err) {
    throw err
  }
}

export const getRecommendationsCollections = async (limit, audience = 'woman') => {
  try {
    return await axiosApiInstance.get(`/goods/popular?limit=${limit}&audience=${audience}`)
  } catch (err) {
    throw err
  }
}

export const getPromotedList = async (limit: number, audience: string) => {
  try {
    return await axiosApiInstance.get(`/collections/promoted?limit=${limit}&audience=${audience}`)
  } catch (err) {
    throw err
  }
}
