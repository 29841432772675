<script setup lang="ts">
import { useAuthModal } from '@/stores/authModal'
import { useAuthStore } from '@/stores/user'
import { computed, ref, watch } from 'vue'
import Popover from 'primevue/popover'
import ProfileContent from '@/blocks/Header/Actions/ProfileDropDown/ProfileContent.vue'

//TODO  вынести иконку доделать выпадашку

const auth = useAuthStore()

const userName = computed(() => auth.userInfo.name)

const op = ref()

const showAuthModalHandler = () => {
  useAuthModal().showAuthModal('signInSignUpWrapper')
}

const toggle = (event) => {
  if (userName.value) {
    op.value.toggle(event)
    return
  }
  showAuthModalHandler()
}

const logoutHandler = (event: Event) => {
  auth.logout()
}
</script>

<template>
  <div class="image-with-dropdown" @click="toggle">
    <div class="language-icon">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.875 7.46875C14.875 8.23125 14.5721 8.96251 14.0329 9.50168C13.4938 10.0408 12.7625 10.3438 12 10.3438C11.2375 10.3438 10.5062 10.0408 9.96707 9.50168C9.4279 8.96251 9.125 8.23125 9.125 7.46875C9.125 6.70625 9.4279 5.97498 9.96707 5.43582C10.5062 4.89665 11.2375 4.59375 12 4.59375C12.7625 4.59375 13.4938 4.89665 14.0329 5.43582C14.5721 5.97498 14.875 6.70625 14.875 7.46875ZM17.0312 7.46875C17.0312 8.80312 16.5012 10.0828 15.5576 11.0264C14.6141 11.9699 13.3344 12.5 12 12.5C10.6656 12.5 9.38591 11.9699 8.44237 11.0264C7.49883 10.0828 6.96875 8.80312 6.96875 7.46875C6.96875 6.13438 7.49883 4.85466 8.44237 3.91112C9.38591 2.96758 10.6656 2.4375 12 2.4375C13.3344 2.4375 14.6141 2.96758 15.5576 3.91112C16.5012 4.85466 17.0312 6.13438 17.0312 7.46875ZM4.09375 18.9688C4.09375 18.6755 4.41 17.8058 5.99125 16.8714C7.45463 16.0075 9.585 15.375 12 15.375C14.415 15.375 16.5454 16.0075 18.0088 16.8714C19.59 17.8058 19.9062 18.6755 19.9062 18.9688C19.9062 19.35 19.7548 19.7156 19.4852 19.9852C19.2156 20.2548 18.85 20.4062 18.4688 20.4062H5.53125C5.15 20.4062 4.78437 20.2548 4.51478 19.9852C4.2452 19.7156 4.09375 19.35 4.09375 18.9688ZM12 13.2188C6.46563 13.2188 1.9375 16.0938 1.9375 18.9688C1.9375 19.9219 2.31613 20.836 2.99009 21.5099C3.66404 22.1839 4.57813 22.5625 5.53125 22.5625H18.4688C19.4219 22.5625 20.336 22.1839 21.0099 21.5099C21.6839 20.836 22.0625 19.9219 22.0625 18.9688C22.0625 16.0938 17.5344 13.2188 12 13.2188Z"
          fill="white"
        />
      </svg>
    </div>
    <Popover
      ref="op"
      :pt="{
        root: {
          style: {
            background: 'rgba(245, 245, 247, 1)',
            borderRadius: '20px',
            overlayArrowLeft: '260px !important',
            padding: 'none'
          }
        },
        content: {
          style: {
            maxWidth: '320px',
            borderRadius: '20px',
            border: 'none',
            padding: '8px'
          }
        }
      }"
    >
      <ProfileContent />
    </Popover>
  </div>
</template>

<style>
.language-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 28px;
  background-color: rgba(245, 245, 247, 0.1);
  cursor: pointer;
}

.image-with-dropdown {
  cursor: pointer;
}
</style>
