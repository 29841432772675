<script setup lang="ts"></script>

<template>
  <div class="banners-header-text__wrapper">
    <p class="banners-title">
      <span>Стильная одежда, аксессуары и декор для </span>
      <span>дома – всё, что идеально подходит твоему вкусу, </span>
      <span>собрано ИИ-стилистом
        <span class="banners-title-link">в индивидуальные подборки!</span></span>
    </p>
  </div>
</template>

<style scoped>
.banners-header-text__wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.banners-title {
  display: flex;
  flex-direction: column;
  font-size: 52px;
  line-height: 60px;
  text-align: right;
}

.banners-title-link {
  color: rgba(101, 128, 199, 1);
}
</style>
