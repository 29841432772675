import axios from 'axios'
import { useAuthStore } from '@/stores/user'

const axiosApiInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true
})


let countOfRequest = 0

const getStoredTokens = () => JSON.parse(localStorage.getItem('userTokens'))

const setStoredTokens = (tokens) => {
  localStorage.setItem('userTokens', JSON.stringify(tokens))
}

const clearStoredTokens = () => {
  localStorage.removeItem('userTokens')
}

const updateTokens = async (authStore) => {
  const storedTokens = getStoredTokens()
  try {
    const { data } = await axiosApiInstance.post('auth/refresh_tokens', {
      auth_token: storedTokens.auth_token,
      refresh_token: storedTokens.refreshToken
    })

    const newTokens = {
      token: data.access_token,
      refreshToken: data.refresh_token,
      expiresIn: data.expires_in + Date.now(),
      refresh_expires_in: data.refresh_expires_in + Date.now()
    }

    authStore.userInfo.accessToken = data.access_token
    authStore.userInfo.refreshToken = data.refresh_token
    setStoredTokens(newTokens)
    countOfRequest = 0
  } catch (err) {
    console.error(err)
    clearStoredTokens()
    authStore.userInfo.accessToken = ''
    authStore.userInfo.refreshToken = ''
  }
}

axiosApiInstance.interceptors.request.use(
  async (config) => {
    countOfRequest++
    if (countOfRequest == 0) {
      const authStore = useAuthStore()
      if (!authStore.userInfo.accessToken) return config
      const storedTokens = getStoredTokens()
      if (Date.now() > storedTokens.expiresIn) {
        await updateTokens(authStore)
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const authStore = useAuthStore()
    const originalRequest = error.config

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      await updateTokens(authStore)
      return axiosApiInstance(originalRequest)
    }

    console.error(error)
    return Promise.reject(error)
  }
)

export default axiosApiInstance
