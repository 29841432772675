<script setup lang="ts">
import IconField from 'primevue/iconfield'
import InputText from 'primevue/inputtext'
import InputIcon from 'primevue/inputicon'
import Button from 'primevue/button'
import { computed, ref, unref, watch } from 'vue'
import MyMultiSelectItem from '@/blocks/MultiSelect/MyMultiSelectItem.vue'
import type { FilterItemOptions } from '@/types/types'
import { useSearch } from '@/stores/search'

const search = useSearch()

const { data } = defineProps<{
  data: FilterItemOptions
}>()

const emits = defineEmits(['clearSelectedFilter'])

const labelName = data.label
const copyDataArray = unref(data)

const searchMultiselectLabelsInpt = ref('')

const findSelected = computed(() => {
  return data.values?.find((item) => item.isSelected === true)
})

const filteredItems = computed(() => {
  if (!searchMultiselectLabelsInpt.value.length) return copyDataArray.values
  return copyDataArray.values.filter((item) =>
    item.name.toLowerCase().includes(searchMultiselectLabelsInpt.value.toLowerCase())
  )
})

function clearSelectedFilter() {
  emits('clearSelectedFilter',data)
}

</script>

<template>
  <div class="multiselect-inner">
    <div v-if="data?.values && data?.values.length >= 8" class="multiselect-inner-search">
      <IconField>
        <InputText
          v-model="searchMultiselectLabelsInpt"
          placeholder="Search"
          :pt="{
            root: {
              style: {
                width: '100%'
              }
            }
          }"
        />
        <InputIcon class="pi pi-search" />
      </IconField>
    </div>
    <div class="multiselect-inner-items-wrapper">
      <my-multi-select-item
        v-for="(multiselectItem, index) in filteredItems"
        v-model="multiselectItem.isSelected"
        :name="multiselectItem.name"
        :quantity="multiselectItem.quantity"
        :color="multiselectItem?.color"
        :key="multiselectItem?.id"
        :id="multiselectItem?.id"
      />
    </div>
    <div  class="multiselect-inner-bottom-buttons">
      <Button v-if="findSelected" @click="clearSelectedFilter" class="btn-height-fix full-width"  label="Сбросить" severity="help" />
    </div>
  </div>
</template>

<style scoped>
.multiselect-inner {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 12px;
}

.multiselect-inner-items-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 190px;
  overflow-y: auto;
  margin-top: 10px;
}

.multiselect-inner-items-wrapper::-webkit-scrollbar {
  width: 4px; /* Ширина всего элемента навигации */
}

.multiselect-inner-items-wrapper::-webkit-scrollbar-track {
  background: transparent; /* Цвет дорожки */
}

.multiselect-inner-items-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(145, 145, 145, 1); /* Цвет бегунка */
  border-radius: 100px; /* Округление бегунка */
  border: 1px solid rgba(145, 145, 145, 1); /* Оформление границ бегунка */
}

.multiselect-inner-bottom-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 0 0;
  gap: 8px;
}
.btn-height-fix {
  height: 40px;
}

.full-width {
  width: 100%;
}
</style>
