<script setup lang="ts">
import Accordion from 'primevue/accordion'
import AccordionPanel from 'primevue/accordionpanel'
import AccordionHeader from 'primevue/accordionheader'
import AccordionContent from 'primevue/accordioncontent'
import Checkbox from 'primevue/checkbox'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import { ref } from 'vue'
import FloatLabel from 'primevue/floatlabel'

const isEmailChecked = ref(true)
const emailValue = ref(null)

const isPhoneChecked = ref(false)
const phoneValue = ref(null)

function subscribe() {
  console.log(emailValue.value, phoneValue.value)
}
</script>

<template>
  <div class="subscribe-form__wrapper">
    <div class="subscribe-form__left-column__wrapper">
      <div class="subscribe-form__left-column-text__wrapper">
        <span class="subscribe-form__left-column__title">
          Получай <span style="color: rgba(101, 128, 199, 1)"> Советы от ИИ-стилиста</span> на почту
          или в Telegram!
        </span>

        <span class="subscribe-form__left-column__subtitle">
          Подпишиь, чтобы получать еженедельные рекомендации. Открывай новые тренды и находи
          идеальные образы легко и просто.
        </span>
      </div>
    </div>
    <div class="subscribe-form__right-column__wrapper">
      <div class="subscribe-from">
        <Accordion
          value="0"
          expand-icon="none"
          collapse-icon="none"
        >
          <AccordionPanel
            value="0"
            :pt="{
              root: {
                style: {
                  border: '0 none'
                }
              }
            }"
          >
            <AccordionHeader>
              <div class="email-subscribe-checkbox__wrapper">
                <Checkbox
                  v-model="isEmailChecked"
                  :binary="true"
                />
                <span>Электронная почта</span>
              </div>
            </AccordionHeader>

            <AccordionContent>
              <FloatLabel>
                <label for="username">Email</label>
                <InputText
                  v-model="emailValue"
                  type="text"
                  :pt="{
                    root: {
                      style: {
                        borderRadius: '8px',
                        width: '100%'
                      }
                    }
                  }"
                />
              </FloatLabel>
            </AccordionContent>
          </AccordionPanel>
          <AccordionPanel
            value="1"
            :pt="{
              root: {
                style: {
                  border: '0 none'
                }
              }
            }"
          >
            <AccordionHeader>
              <div class="email-subscribe-checkbox__wrapper">
                <Checkbox
                  v-model="isPhoneChecked"
                  :binary="true"
                />
                <span>СМС</span>
              </div>
            </AccordionHeader>
            <AccordionContent>
              <FloatLabel>
                <label for="username">Phone</label>
                <InputText
                  v-model="phoneValue"
                  type="text"
                  :pt="{
                    root: {
                      style: {
                        borderRadius: '8px',
                        width: '100%'
                      }
                    }
                  }"
                />
              </FloatLabel>
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
        <Button
          :pt="{
            root: {
              style: {
                backgroundColor: 'rgba(36, 49, 74, 1)',
                padding: '16px 24px 16px 24px',
                border: '1px solid rgba(36, 49, 74, 1)',
                borderRadius: '40px',
                height: '48px',
                width: '150px'
              }
            }
          }"
          label="Подписаться"
          @click="subscribe"
        />
        <p class="subscribe-form-text">
          Регистрируясь, вы соглашаетесь получать маркетинговые сообщения по электронной почте и/или
          по СМС и подтверждаете, что прочитали «Политикой конфиденциальности». Чтобы отказаться от
          рассылки, нажмите на кнопку «Отписаться» в конце письма или ответьте STOP на наше
          СМС-сообщение.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.subscribe-form__background {
  width: 100%;
}

.subscribe-form-text {
  max-width: 570px;
  padding: 0 0 0 12px;
  margin-top: 40px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(61, 61, 61, 0.5);
}

.subscribe-form__wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 140px 100px 240px 100px;
  border-radius: 40px;
  background: white;
  z-index: 5;
}

.subscribe-form__left-column__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 44%;
}

.subscribe-form__right-column__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 44%;
}

.subscribe-from {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.email-subscribe-checkbox__wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: white;
  gap: 24px;
}

.subscribe-form__left-column-text__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 628px;
  gap: 20px;
}

.subscribe-form__left-column__title {
  font-size: 40px;
  line-height: 48px;
  width: 100%;
}

.subscribe-form__left-column__subtitle {
  font-size: 16px;
  line-height: 21px;
}

:deep(.p-float-label label) {
  margin-top: -0.5rem !important;
}

:deep(.p-checkbox-box) {
  border: 1px solid rgba(36, 49, 74, 1);
}

:deep(.p-checkbox.p-highlight .p-checkbox-box) {
  background: rgba(36, 49, 74, 1);
}

:deep(.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box) {
  background: rgba(36, 49, 74, 1);
}
</style>
