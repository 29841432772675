<template>
  <div class="popular-brands-control">
    <button class="popular-brands-navigation_btn" @click="swiper.slideTo(swiper.activeIndex - 8)">
      <i class="pi pi-arrow-left" :style="{ fontSize: '1rem', color: controlColor }"></i>
    </button>
    <button class="popular-brands-navigation_btn" @click="swiper.slideTo(swiper.activeIndex + 8)">
      <i class="pi pi-arrow-right" :style="{ fontSize: '1rem', color: controlColor }"></i>
    </button>
  </div>
</template>
<script setup lang="ts">
import { useSwiper } from 'swiper/vue'

const { controlColor } = defineProps<{
  controlColor: string
}>()

const swiper = useSwiper()
</script>

<style scoped>
.popular-brands-control {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}

.popular-brands-navigation_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: transparent;
  border: 1px solid v-bind(controlColor);
  border-radius: 50%;
  cursor: pointer;
}
</style>
