import { ref } from 'vue'
import { defineStore } from 'pinia'
import SetNewPassword from '@/blocks/Modals/AuthModals/SetNewPassword.vue'
import ResetPassword from '@/blocks/Modals/AuthModals/ResetPassword.vue'
import VerifyUser from '@/blocks/Modals/AuthModals/VerifyUser.vue'
import EndOfRegistration from '@/blocks/Modals/AuthModals/EndOfRegistration.vue'
import CheckEmailResetPassword from '@/blocks/Modals/AuthModals/CheckEmailResetPassword.vue'
import ResetPasswordSuccess from '@/blocks/Modals/AuthModals/ResetPasswordSuccess.vue'
import SignInSignUpWrapper from '@/blocks/Modals/AuthModals/SignInSignUpWrapper/SignInSignUpWrapper.vue'

const views = {
  signInSignUpWrapper: SignInSignUpWrapper,
  verifyUser: VerifyUser,
  setNewPassword: SetNewPassword,
  resetPassword: ResetPassword,
  endOfRegistration: EndOfRegistration,
  checkEmailResetPassword: CheckEmailResetPassword,
  resetPasswordSuccess: ResetPasswordSuccess
}

export const useAuthModal = defineStore('authModal', () => {
  const authModal = ref({
    isShow: false,
    isError: false,
    isLoading: false,
    currentView: SignInSignUpWrapper
  })

  const changeViewHandler = (view) => {
    authModal.value.currentView = views[view] || SignInSignUpWrapper
  }

  const showAuthModal = (currentView) => {
    changeViewHandler(currentView)
    authModal.value.isShow = true
  }

  const hideAuthModal = () => {
    authModal.value.isShow = false
  }

  return { authModal, showAuthModal, hideAuthModal }
})
