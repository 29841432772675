import { sendMessage } from '@/api/websocket'

const url = 'ws://localhost:8080/api/v0/websocket/connect?token=Bearer'

class WebSocketService {
  private socket: WebSocket | null = null

  connect(token: string) {
    if (this.socket?.readyState === WebSocket.OPEN) return
    this.socket = new WebSocket(`${url} ${encodeURIComponent(token)}`)

    this.socket.addEventListener('open', () => {
      console.log('Connected to WebSocket server')
    })

    this.socket.addEventListener('close', () => {
      console.log('Disconnected from WebSocket server')
    })
  }

  on(callback: (event: MessageEvent<any>) => void) {
    if (!this.socket) return
    this.socket.addEventListener('message', (messageEvent) => {
      try {
        const data = messageEvent.data
        callback(data)
      } catch (error) {
        console.error('Error parsing WebSocket message', error)
      }
    })
  }

  send(token: string, data: string) {
    if (this.socket?.readyState === WebSocket.OPEN) {
      return sendMessage(token, data)
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close()
      this.socket = null
    }
  }
}

export const webSocketService = new WebSocketService()
