<script setup lang="ts">
import { ref } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import FloatLabel from 'primevue/floatlabel'
import { useAuthModal } from '@/stores/authModal'
import { useAuthStore } from '@/stores/user'

const email = ref('')

function signInHandler() {
  useAuthModal().showAuthModal('signIn')
}
function forgotPasswordHandler() {
  useAuthStore().forgotPassword(email.value)
}
</script>

<template>
  <div class="reset-password__content">
    <span class="reset-password__title">Не помнишь пароль?</span>
    <div class="reset-password__content__wrapper">
      <div class="reset-password__inputs">
        <div class="reset-password__text">
          Не беда! Введи свой адрес электронной почты и мы вышлем ссылку для сброса пароля
        </div>
        <FloatLabel>
          <InputText
            v-model="email"
            class="email"
            :pt="{
              root: {
                style: {
                  width: '100%',
                  borderRadius: '8px'
                }
              }
            }"
          />
          <label for="email">Введи адрес электронной почты</label>
        </FloatLabel>
      </div>
    </div>
    <div class="reset-password__submit_btn__wrapper">
      <Button
        class="reset-password-submit"
        label="Сбросить пароль"
        @click="forgotPasswordHandler"
      />
    </div>
    <div class="old__user">
      <span>Вернуться к странице</span>
      <Button
        class="sign-in_link"
        label="Входа"
        text
        @click="signInHandler"
      />
    </div>
  </div>
</template>
<style scoped>
.reset-password__content {
  display: flex;
  border-radius: 40px;
  padding: 0 40px 40px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  gap: 20px;
}

.reset-password__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
}

.reset-password__content__wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.reset-password_third_party button {
  display: flex;
  border-radius: 8px;
  gap: 8px;
  padding: 12px 20px;
}

.reset-password__inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reset-password__inputs__wrapper {
  width: 100%;
}

.email,
.password {
  width: 100%;
}

.reset-password__text {
  display: flex;
  text-align: center;
}

.reset-password__submit_btn__wrapper {
  display: flex;
  width: 100%;
}

.reset-password-submit {
  width: 100%;
  border-radius: 100px;
}

.old__user {
  display: flex;
  align-items: center;
}
</style>
