import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { setFilters, filtersList, shoppingList } from '@/api/search'
import type { arrayOfFilters, FilterItem } from '@/types/types'
import type { shoppingListParams, shoppingListPayload, SortingOptions } from '@/types/searchItemsTypes'
import { useRoute } from 'vue-router'
import { updateObjects } from '@/helpers/createFiltersStateFromCombinetWithUrlState'

export const useSearch = defineStore('search', () => {
  const error = ref('')
  const loader = ref(false)

  const route = useRoute()

  const currentFilters = ref<arrayOfFilters>([])
  const suggestedFilters = ref([])
  const responseDefineFilters = ref([])
  const countOfItemInRow = ref(4)
  const currentSort = ref<SortingOptions>('default')
  const pageInfo = ref({})
  const itemsBehavior = ref('default')
  const searchResult = ref([])
  const totalQuantity = ref(0)

  const defineFilters = async (searchText: string) => {
    error.value = ''
    loader.value = true
    try {
      return setFilters(searchText).then((res) => {
        responseDefineFilters.value = res.data.defined_filters
        return res
      })
    } catch (err) {
      throw err
    } finally {
      loader.value = false
    }
  }

  const getSearchList = async (payload: shoppingListPayload,params:shoppingListParams) => {
    error.value = ''
    loader.value = true
    try {
      return shoppingList(payload,params).then(res => {
        suggestedFilters.value = res.data.suggested_filters
        pageInfo.value = res.data.page_info
        searchResult.value = res.data.search_results
        return res
      })
    } catch (err) {
      throw err
    } finally {
      loader.value = false
    }
  }

  const getFiltersList = async (payload: shoppingListPayload) => {
    error.value = ''
    loader.value = true
    try {
      return filtersList(payload).then((res) => {
        const test = updateObjects(route.query, res.data.filters)
        totalQuantity.value = res.data.total_quantity
        currentFilters.value = test
        return res
      })
    } catch (err) {
      throw err
    } finally {
      loader.value = false
    }
  }

  const setCheckBoxValueState = (id: number, value: boolean) => {
    if (currentFilters.value)
      for (let obj of currentFilters.value) {
        if (obj.options.values)
          for (let item of obj.options.values) {
            if (item.id === id) {
              item.isSelected = value
            }
          }
      }
  }

  const changePriceFromUserMenu = async (min: number, max: number, indexOfCategory: number) => {
    if (currentFilters.value) {
      currentFilters.value[indexOfCategory].options.currentMin = min
      currentFilters.value[indexOfCategory].options.currentMax = max
    }
  }

  const clearAllFilters = async () => {
    currentFilters.value?.forEach((currentFiltersItem) => {
      currentFiltersItem.options.values?.forEach((item) => {
        item.isSelected = false
      })
    })
  }

  const clearFiltersToSelectedFilterType = async (item: FilterItem) => {
    const selectedItem = currentFilters.value.find((currentFiltersItem) => currentFiltersItem.options.label === item?.label || currentFiltersItem.options.label === item.options?.label)
    if (!selectedItem) return
    selectedItem?.options.values.forEach((item) => {
      item.isSelected = false
    })
  }

  const setItemsBehavior = async (behavior:string) => {
    itemsBehavior.value = behavior
  }

  const setSortingOptions = (sort:SortingOptions) => {
    currentSort.value = sort
  }

  return {
    getSearchList,
    getFiltersList,
    defineFilters,
    currentFilters,
    responseDefineFilters,
    countOfItemInRow,
    currentSort,
    suggestedFilters,
    setCheckBoxValueState,
    changePriceFromUserMenu,
    clearAllFilters,
    clearFiltersToSelectedFilterType,
    pageInfo,
    itemsBehavior,
    setItemsBehavior,
    setSortingOptions,
    searchResult,
    totalQuantity,
  }
})


// const selectedFiltersForUrl = computed(() => {
//   if(currentFilters.value)
//     return currentFilters.value
//       .map((item) => {
//         if (item.type === 'MULTI_SELECT' || item.type === 'COLOR_SELECT') {
//           const filteredValues = item?.options?.values.filter((value) => value.isSelected === true)
//           const labelForFilteringAndUrlSet = filteredValues.length
//             ? item.options.label
//             : 'forDelete'
//
//           return { [labelForFilteringAndUrlSet]: filteredValues.map((item) => item.name) }
//         }
//
//         if (item.type === 'GRAPH_SELECT') {
//           return { [item.options.label]: [item.options.currentMin, item.options.currentMax] }
//         }
//         return item
//       })
//       .filter((item) => {
//         return !item.hasOwnProperty('forDelete')
//       })
// })
//
// const selectedMultiSelectFilters = computed(() => {
//   return currentFilters.value?.filter((item) => {
//     if(item.options.label === 'Цена') {
//       return item
//     }
//
//     if (item.options.values && item.options.values.filter((value) => value.isSelected === true).length) {
//       return item
//     }
//   })
// })
//
//
// const selectedFiltersForBackEndApi = computed(() => {
//   if(selectedMultiSelectFilters.value?.length)
//     return selectedMultiSelectFilters.value.map(item => {
//       if(item.options.label === 'Цена') {
//         return {
//           filter_type: item.options.label,
//           values:{
//             lte:item.options.currentMin,
//             gte:item.options.currentMax
//           }
//         }
//       }
//
//       return {
//         filter_type: item.options.label,
//         values: item.options.values?.filter(value => value.isSelected).map((item) => item.name),
//       }
//     })
// })
//
// const selectedFiltersForBackEndApiFromUrl = computed(() => {
//   if(route.query) {
//     let testArr = []
//     for(let key in route.query) {
//       if(key === 'Цена') {
//         testArr.push({
//           filter_type: key,
//           values: {
//             lte: route.query[key][0] ?? 0,
//             gte: route.query[key][1] ?? 100,
//           },
//         })
//       } else  {
//         testArr.push({
//           filter_type: key,
//           values: [route.query[key]].flat(),
//         })
//       }
//     }
//     return testArr
//   }
//   return responseDefineFilters.value
// })
//
// const changeSortOption = async (sortOption:string) => {
//   currentSort.value = sortOption
// }
//
// const setCheckBoxValueState = (id:number,value:boolean) => {
//   for (let obj of currentFilters.value) {
//     if(obj.options.values)
//       for (let item of obj.options.values) {
//         if (item.id === id) {
//           item.isSelected = value
//         }
//       }
//   }
// }
//
// const setCurrentFiltersToUrl = async () => {
//   const res = {}
//
//
//   for (let i = 0; i < selectedFiltersForUrl.value.length; i++) {
//     for (let key in selectedFiltersForUrl.value[i]) {
//       res[key] = selectedFiltersForUrl.value[i][key]
//     }
//   }
//
//   if(route.query.page) {
//     res.page = route.query.page
//   }
//
//   await router.push({
//     query: res
//   })
// }
//
// const changeFilterItemFromUserMenu = async (
//   filterType: string,
//   filterValue: string,
//   isChecked: boolean
// ) => {
//   if (!currentFilters) return
//   const indexOfCategory = currentFilters.value?.findIndex(
//     (item) => item.options.label === filterType
//   )
//   if (!indexOfCategory === -1) return
//   const indexOfValueInsideCategory = currentFilters.value[
//     indexOfCategory
//     ].options.values.findIndex((item) => item.name.toLowerCase() === filterValue.toLowerCase())
//   if (indexOfValueInsideCategory === -1) return
//   currentFilters.value[indexOfCategory].options.values[indexOfValueInsideCategory].isSelected =
//     isChecked
//
// }
//
// const changePriceFromUserMenu = async (min: number, max: number, indexOfCategory: number) => {
//   currentFilters.value[indexOfCategory].options.currentMin = min
//   currentFilters.value[indexOfCategory].options.currentMax = max
//
// }
//
// const defineFiltersFromUrl = async () => {
//   currentFilters.value?.forEach((currentFiltersItem) => {
//     for (let label in route.query) {
//       if (currentFiltersItem.options.label === label) {
//         currentFiltersItem.options.values?.forEach((itemValue) => {
//           if (route.query[label]?.includes(itemValue.name)) {
//             itemValue.isSelected = true
//           }
//         })
//       }
//     }
//   })
// }
//
// const clearFiltersToSelectedFilterType = async (item: FilterItem) => {
//   const selectedItem = currentFilters.value.find(
//     (currentFiltersItem) => currentFiltersItem.options.label === item.options.label
//   )
//   if (selectedItem)
//     selectedItem?.options.values.forEach((item) => {
//       item.isSelected = false
//     })
// }
//
// const clearAllFilters = async () => {
//   currentFilters.value?.forEach((currentFiltersItem) => {
//     currentFiltersItem.options.values?.forEach((item) => {
//       item.isSelected = false
//     })
//   })
//
// }