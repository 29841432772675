<script setup lang="ts">
import Card from 'primevue/card'
import SearchPagePagination from '@/blocks/SearchPage/SearchPageContent/SearchPageListWrapper/SearchPagePagination.vue'
import Button from 'primevue/button'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, ref, watch } from 'vue'
import { useSearch } from '@/stores/search'
import { parseFiltersFromUrl } from '@/helpers/parseFiltersFromUrl'
import { useImageSearch } from '@/stores/imageSearch'



const search = useSearch()
const route = useRoute()
const router = useRouter()
const imageSearch = useImageSearch()

const imageUrl = computed(() => imageSearch.currentImageLink)
const definedFilters = computed(() => search.responseDefineFilters)
const searchedText = computed(() => route.params.searchText)
const queryFilters = computed(() => route.query)
const currentPage = computed(() => route.query.page)
const countOfItemsInRow = computed(() => search.countOfItemInRow)
const behavior = computed(() => search.itemsBehavior)
const searchSort = computed(() => search.currentSort)

const resultedSearchItems = ref([])
const pageInfo = ref(null)


function productClickHandler(id: string) {
  router.push({ path: `/product=${id}` })
}

async function loadMoreHandler() {
  await search.setItemsBehavior('add')
  await router.push({
    query: {
      ...route.query,
      page: route.query.page ? Number(route.query.page) + 1 : 2
    }
  })
}



watch([queryFilters,countOfItemsInRow,searchSort,imageUrl,searchedText],async (value, oldValue, onCleanup) => {
  const filtersFromUrlForPayload = parseFiltersFromUrl(route.query)

  const filtersToBackEnd = filtersFromUrlForPayload.length
    ? filtersFromUrlForPayload
    : definedFilters.value.length
      ? definedFilters.value
      : []

  const response = await search.getSearchList({
    filters:filtersToBackEnd,
    img_url:imageUrl.value,
    search_text:String(searchedText.value),
    sorting_option: searchSort.value
    },
    {
      page_num:currentPage.value ? Number(currentPage.value) : 1,
      page_size:countOfItemsInRow.value*3
    }
  )

  if (behavior.value === 'default') {
    resultedSearchItems.value = response.data.search_results
    pageInfo.value = response.data.page_info
  } else {
    resultedSearchItems.value = resultedSearchItems.value?.concat(response.data.search_results)
    pageInfo.value = response.data.page_info
    await search.setItemsBehavior('default')
  }
})

onMounted( async () => {
  const filtersFromUrlForPayload = parseFiltersFromUrl(route.query)

  const filtersToBackEnd = filtersFromUrlForPayload.length
    ? filtersFromUrlForPayload
    : definedFilters.value.length
      ? definedFilters.value
      : []

  const response = await search.getSearchList({
    filters:filtersToBackEnd,
    img_url:imageUrl.value,
    search_text:String(searchedText.value),
    sorting_option:searchSort.value
  },
    {
      page_num:currentPage.value ? Number(currentPage.value) : 1,
      page_size:countOfItemsInRow.value*3
    })

  resultedSearchItems.value = response.data.search_results
})



</script>

<template>
  <div class="search-result-inner">
    <div class="search-result-items__wrapper">
      <Card
        v-for="searchResultItem in resultedSearchItems"
        @click="productClickHandler(searchResultItem.id)"
        :style="{
          width: `calc(100%/${countOfItemsInRow} - 20px)`,
          overflow: 'hidden'
        }"
        :pt="{
          header:{
            style:{
              padding:'8px'
            }
          }
        }"
      >
        <template #header>
          <div class="card-item-image">
            <img class="card-image" alt="user header" :src="searchResultItem?.image_url" />
          </div>
        </template>
        <template #title>от {{ searchResultItem?.price.lte }}</template>
        <template #subtitle>{{ searchResultItem?.name }}</template>
        <template #content>
          <div class="card-footer">
            <span class="card-brand-name">{{ searchResultItem?.brand }} </span>
            <i class="pi pi-heart" />
          </div>
        </template>
        <template #footer> </template>
      </Card>
    </div>
    <search-page-pagination @load-more="loadMoreHandler" />
  </div>
</template>

<style scoped>
.load-more {
  margin-top: 40px;
}

.card-footer {
  display: flex;
  justify-content: space-between;
}

.pi-heart {
  cursor: pointer;
  font-size: 28px;
}

.pi-heart:hover {
  color: rgba(61, 61, 61, 1);
}

.search-result-inner {
  display: flex;
  flex-direction: column;
}

.search-result-items__wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

.card-item-image {
  display: flex;
  justify-content: center;
  max-width: 384px;
  max-height: 374px;
  border-radius: 32px;
  overflow: hidden;
}

.card-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}
</style>
