<script setup lang="ts">
import Button from 'primevue/button'
import { useSearch } from '@/stores/search'
import { computed } from 'vue'
import SelectedItemMultiSelect from '@/blocks/MultiSelect/SelectedItemMultiSelect.vue'

const search = useSearch()

const selectedFilters = computed(() => search.currentFilters.filter((item) => {
  if(item.options.label === 'price') {
    return false
  }

  if (item.options.values && item.options.values.filter((value) => value.isSelected === true).length) {
    return item
  }
}))


function clearAllFilter() {
  search.clearAllFilters()
}
</script>



<template>
  <div
    v-if="selectedFilters?.length"
    class="selected-filters__wrapper"
  >
    <div class="selected-filters">
      <div
        v-for="(selectedFiltersType, index) in selectedFilters"
        :key="index"
      >
        <selected-item-multi-select
          :append-to="'self'"
          :key="index + selectedFiltersType.options.label"
          :data="selectedFiltersType"
        />
      </div>
    </div>
    <Button
      label="Удалить все"
      :pt="{
        root: {
          style: {
            color: 'rgba(61, 61, 61, 1)'
          }
        }
      }"
      text
      @click="clearAllFilter"
    />
  </div>
</template>

<style scoped>
.selected-filters__wrapper {
  display: flex;
  gap: 8px;
}

.selected-filters {
  display: flex;
  align-items: center;
  gap: 8px;
}

.selected-filter-btn {
  padding: 4px 4px 4px 12px;
  height: 40px;
}
</style>
