<script setup lang="ts"></script>

<template>
  <div class="logo">
    <router-link
      to="/"
      rel="noopener"
    >
      <img
        class="logo__logo"
        src="@/assets/img/logo.svg"
        alt="Logo"
      >
    </router-link>
  </div>
</template>

<style scoped>
.logo {
  width: fit-content;
}

.logo a {
  color: black;
  font-weight: 600;
  font-size: 42px;
}
</style>
