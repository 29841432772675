<script setup lang="ts">
import { ref } from 'vue'

const filters = ref([
  {
    title: 'Новые поступления'
  },
  {
    title: 'Распродажа'
  },
  {
    title: 'Бренды'
  },
  {
    title: 'Байеры'
  },
  {
    title: 'Стоки'
  },
  {
    title: 'Одежда'
  },
  {
    title: 'Обувь'
  },
  {
    title: 'Сумки'
  },
  {
    title: 'Акессуары'
  },
  {
    title: 'Дом'
  }
])
</script>

<template>
  <div class="filters-menu__wrapper">
    <div
      v-for="item in filters"
      class="filters-item"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<style scoped>
.filters-menu__wrapper {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.filters-item {
  font-weight: 300;
  font-size: 18px;
  padding: 0px 24px 20px 24px;
  color: rgba(215, 217, 219, 1);
}
</style>
