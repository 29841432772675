import axiosApiInstance from '@/api/api'
import type { shoppingListParams, shoppingListPayload } from '@/types/searchItemsTypes'

export const setFilters = async (searchText) => {
  try {
    return await axiosApiInstance.post(`/shopping-list/define-filters`, {
      search_text: searchText
    })
  } catch (err) {
    throw err
  }
}

export const shoppingList = async (payload:shoppingListPayload,params:shoppingListParams) => {
  try {
    return await axiosApiInstance.post(
      `/shopping-list`,payload,
      {
        params: params
      }
    )
  } catch (err) {
    throw err
  }
}

export const searchHistory = async (size: number) => {
  try {
    return await axiosApiInstance.get(`/search-history/get_history`, {
      params: {
        size: size
      }
    })
  } catch (err) {
    throw err
  }
}

export const filtersList = async (payload:shoppingListPayload) => {
  try {
    return await axiosApiInstance.post(`/shopping-list/get-filters`, payload)
  } catch (err) {
    throw err
  }
}

export const uploadImage = async (image:any) => {
  try {

    return await axiosApiInstance.postForm(`/shopping-list/upload_img`, {
        img: image
    })
  } catch (err) {
    throw err
  }
}

export const autocomplete = async (payload) => {
  try {
    return await axiosApiInstance.get(`/shopping-list/autocomplete`, {
      params: payload
    })
  } catch (err) {
    throw err
  }
}
