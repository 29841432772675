<script setup lang="ts"></script>

<template>
  <svg
    width="252"
    height="54"
    viewBox="0 0 252 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M140.314 54L131.767 41.6482H83.6755L75.2074 54H63.4644L101.33 0H114.192L152.137 54H140.314ZM88.4684 34.6804H126.973L107.721 6.73007L88.4684 34.6804Z"
      fill="white"
    />
    <path
      d="M167.429 54H157.682V0H167.429V54Z"
      fill="white"
    />
    <path
      d="M188.492 54H178.746V0H231.073C244.413 0 252 5.06733 252 17.6568C252 29.0586 245.611 34.6804 232.99 35.1554L226.918 35.2346L231.789 38.56L250.884 54H238.42L216.053 35.6305H188.492V54ZM188.492 8.31369V28.1877H230.432C238.659 28.1877 242.016 25.3373 242.016 18.2904C242.016 11.085 238.659 8.31369 230.432 8.31369H188.492Z"
      fill="white"
    />
    <path
      d="M9.74618 54H0V0H70.5392V8.31369H9.74618V25.179H69.0213V32.9386H9.74618V54Z"
      fill="white"
    />
  </svg>
</template>

<style scoped></style>
