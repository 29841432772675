<script setup lang="ts"></script>

<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#E1D5BF"/>
    <path d="M28.5319 18.831L27.5833 18.0695V19.2858V27.7499C27.5833 27.7499 27.5833 27.75 27.5833 27.75C27.583 29.2038 27.13 30.6215 26.2872 31.8062C25.4444 32.9908 24.2536 33.8837 22.8802 34.3608C21.5068 34.8378 20.0189 34.8754 18.6232 34.4683C17.2275 34.0612 15.9931 33.2295 15.0916 32.0889C14.1901 30.9483 13.6661 29.5553 13.5923 28.1032C13.5186 26.6512 13.8989 25.2123 14.6803 23.9862C15.4616 22.7601 16.6054 21.8078 17.9527 21.2614C19.1583 20.7725 20.4732 20.6304 21.75 20.8462V23.2307C21.0667 23.0543 20.3484 23.0341 19.6495 23.1769C18.6588 23.3792 17.7606 23.8979 17.0901 24.6548C16.4197 25.4116 16.0132 26.3658 15.9318 27.3737C15.8504 28.3815 16.0986 29.3886 16.639 30.2432C17.1794 31.0978 17.9828 31.7538 18.9282 32.1124C19.8736 32.4711 20.9099 32.5129 21.8811 32.2317C22.8524 31.9505 23.706 31.3615 24.3136 30.5532C24.9212 29.745 25.2498 28.7613 25.25 27.7501V27.75V12.5833H27.6099C27.7457 14.0712 28.3976 15.4722 29.4627 16.5373C30.5278 17.6024 31.9288 18.2543 33.4167 18.3901V20.7313C31.6367 20.6144 29.9301 19.9535 28.5319 18.831Z" fill="#E1D5BF" stroke="#E1D5BF" stroke-width="1.16667"/>
  </svg>

</template>

<style scoped></style>
