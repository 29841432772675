<script setup lang="ts">
import Button from 'primevue/button'
import { Autoplay, Controller, FreeMode, Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/vue'

const { suggestedFilters } = defineProps({
  suggestedFilters: Array
})

const swiperModules = [FreeMode, Autoplay, Navigation, Controller]

const emit = defineEmits<{
  (e: 'suggestedFilterClick', filterItem: any): void
}>()

function handleSuggestedFilterClick(filterItem) {
  emit('suggestedFilterClick', filterItem)
}


</script>

<template>
  <div class="suggested-filters__wrapper">
    <swiper
      :space-between="20"
      :slides-per-view="'auto'"
      :modules="swiperModules"
      :navigation="true"
      class="suggested-filters__inner"
    >
      <swiper-slide
        v-for="(filter, index) in suggestedFilters"
        :key="index"
        class="suggested-filters__item"
      >
        <Button
          :pt="{
            label: {
              style: {
                textWrap: 'nowrap'
              }
            }
          }"
          @click="handleSuggestedFilterClick(filter)"
          :label="filter.filter_value"
          outlined
          severity="secondary"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<style scoped>
.suggested-filters__wrapper {
  position: relative;
  max-width: 72%;
  padding: 0 40px;
}

.suggested-filters__item {
  width:auto;
}

:deep(.swiper) {
  position: unset;
}

:deep(.swiper-button-prev::after) {
  font-size: 22px;
  font-weight: 600;
  color:rgba(61, 61, 61, 1);
}


:deep(.swiper-button-next::after) {
  font-size: 22px;
  font-weight: 600;
  color:rgba(61, 61, 61, 1);
}

:deep(.swiper-button-disabled) {
  visibility: hidden;
}

</style>
