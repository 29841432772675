<template>
  <div class="card flex justify-content-center">
    <SearchImageWrapper
      :base64data="base64data"
      @show-modal="visible = true"
    />

    <Dialog
      ref="dialogFileUpload"
      v-model:visible="visible"
      :pt="{
        root: {
          style: {
            border: '20px',
            borderColor: 'transparent',
            background: 'rgba(245, 245, 247, 1)'
          }
        },
        mask: {
          style: 'backdrop-filter: blur(2px)'
        },
        content: {
          style: {
            border: '20px',
            borderColor: 'transparent'
          }
        }
      }"
    >
      <template #container="{ closeCallback }">
        <DropImageModalContent
          :base64data="base64data"
          @show-modal="visible = true"
          @close-modal="closeCallback"
          @set-base64-image-data="handlerBase64Data"
          @custom-upload-image="customBase64Uploader"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog'

import { computed, ref } from 'vue'
import SearchImageWrapper from '@/blocks/HomeWrapper/Search/DragAndDrop/Image/SearchImageWrapper.vue'
import DropImageModalContent from '@/blocks/HomeWrapper/Search/DragAndDrop/DropImageModalContent/DropImageModalContent.vue'
import { uploadImage } from '@/api/search'
import { useImageSearch } from '@/stores/imageSearch'
import { useToast } from 'primevue/usetoast'

const imageSearch = useImageSearch()
const toast = useToast()


const visible = ref(false)
const dialogFileUpload = ref()
const base64data = computed(() => imageSearch.imageBase64Data)
const file = computed(() => imageSearch.imageFile)

const showToast = (msg) => {
  toast.add({ severity: 'error', summary: 'Info', detail: msg, life: 3000 })
}

function handlerBase64Data(base64ImageData) {
  imageSearch.imageBase64Data = base64ImageData
  dialogFileUpload.value.close()
}

const customBase64Uploader = async (event) => {
  imageSearch.imageFile = event.files[0]
  const reader = new FileReader()
  let blob = await fetch(file.value.objectURL).then((r) => r.blob()) //blob:url

  reader.readAsDataURL(blob)

  reader.onloadend = async function () {
    imageSearch.imageBase64Data = reader.result

    const response = await uploadImage(file.value)

    if (response.status === 200) {
      imageSearch.currentImageLink = response.data.img_url
    } else {
      showToast('Что то пошло не так ошибка в ответе от сервера')
    }


    dialogFileUpload.value.close()
  }
}
</script>

<style scoped></style>
