<template>
  <div class="header">
    <div class="header__inner">
      <Logo />
      <div class="header__content">
        <div class="header__search">
          <BaseTabs />
          <div
            v-show="isHeaderHidden"
            class="header__search-icon"
            @click="toggleSearch"
          >
            <i :class="searchIconClass" />
          </div>
        </div>
      </div>
      <div class="header__actions">
        <Actions />
      </div>
    </div>
    <div class="header-megamenu">
      <HeaderMegaMenu v-if="isMegaMenuVisibleImmediately || isMegaMenuVisible" />
    </div>
    <div
      v-show="showSearch"
      class="header__search-input"
    >
      <SearchInput @close-search="toggleSearch" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Logo from '@/blocks/Header/Logo/Logo.vue'
import Actions from '@/blocks/Header/Actions/ActionsWrapper.vue'
import BaseTabs from '@/components/UI/Tabs/BaseTabs.vue'
import SearchInput from '@/blocks/HomeWrapper/Search/SearchInputWrapper/SearchInput.vue'
import { onMounted, onUnmounted, ref, computed } from 'vue'
import HeaderMegaMenu from '@/blocks/Header/Megamenu/HeaderMegaMenu.vue'

const { isMegaMenuVisibleImmediately } = defineProps({
  isMegaMenuVisibleImmediately: Boolean
})

const isHeaderHidden = ref(isMegaMenuVisibleImmediately)
const isSearchVisible = ref(false)
const isMegaMenuVisible = ref(false)

const searchIconClass = computed(() => (isSearchVisible.value ? 'pi pi-times' : 'pi pi-search'))
const showSearch = computed(
  () => (isMegaMenuVisibleImmediately || isHeaderHidden.value) && isSearchVisible.value
)

function toggleSearch() {
  isSearchVisible.value = !isSearchVisible.value
}

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  isHeaderHidden.value = scrollTop > 600 || isMegaMenuVisibleImmediately
  isMegaMenuVisible.value = scrollTop > 700
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(26, 33, 51, 0.9);
  position: fixed;
  z-index: 11;
  backdrop-filter: blur(4px);
}

.header__inner {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66%;
}

.header__search {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.header__search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: white;
  color: rgba(145, 145, 145, 1);
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2rem;
}

.header__actions {
  display: flex;
  gap: 80px;
}

.header__search-input {
  position: relative;
  width: 50%;
  padding-bottom: 90px;
}
</style>
