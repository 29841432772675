<script setup lang="ts">
import Divider from 'primevue/divider'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getRecommendationsCollections } from '@/api/recommendations'
import Button from 'primevue/button'

const router = useRouter()
const route = useRoute()

const collectionsData = ref([])
const width = ref(window.innerWidth)

const updateWidth = () => {
  width.value = window.innerWidth
}

const numberOfColumnsForMansoryWall = computed(() => {
  return width.value < 1440 ? 3 : 4
})

watch(
  route,
  async () => {
    if (route.query.audience) {
      await getRecommendationsCollections(24, route.query.audience).then((res) => {
        collectionsData.value = res.data.items
      })
    }
  },
  { deep: true }
)

onMounted(async () => {
  await getRecommendationsCollections(24, route.query.audience).then((res) => {
    collectionsData.value = res.data.items
  })

  window.addEventListener('resize', updateWidth)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateWidth)
})
</script>
<template>
  <div class="mansory-wall-backgrund">
    <div class="recommendation_content_wrapper">
      <div class="recommendation_top_content__wrapper">
        <div class="top-content__text">
          Самые <span style="color: rgba(147, 120, 101, 1)">популярные товары</span> – стильные
          находки, которые ты точно оценишь!
        </div>
        <div v-if="false" class="top-content-filters">
          <div class="top-content-upper-filters__wrapper">
            <div
              v-for="item in 6"
              class="top-content-filters__item"
            >
              Одежда
            </div>
          </div>
          <Divider />
          <div class="top-content-selected-filters__wrapper">
            <div
              v-for="item in 2"
              class="top-content-selected-filters__item"
            >
              Платье <i class="pi pi-times" />
            </div>
          </div>
        </div>
      </div>
      <div class="mansory-wall-wrapper">
        <masonry-wall
          :items="collectionsData"
          :min-columns="numberOfColumnsForMansoryWall"
          :max-columns="4"
          :gap="40"
        >
          <template #default="{ item, index }">
            <div class="mansory-item__wrapper">
              <div
                v-if="item.image_url"
                class="mansory-item-img__wrapper"
              >
                <img
                  class="image-mansory"
                  :src="item.image_url"
                >
              </div>
              <div class="mansory-item-text__wrapper">
                <span>от <span style="font-size: 24px">${{ item?.price.lte }}</span></span>
                <p class="text-subitem">
                  <span>{{ item?.name }}</span>
                  <span class="brand-name-mansory-item">{{ item?.brand }}
                    <i
                      class="pi pi-heart"
                      style="font-size: 1.5rem"
                    />
                  </span>
                </p>
              </div>
            </div>
          </template>
        </masonry-wall>
      </div>
      <div class="show-more-popular-btn">
        <Button
          label="Показать больше популярных товаров"
          severity="info"
          icon="pi pi-arrow-right"
          icon-pos="right"
          class="btn-styc"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.mansory-wall-backgrund {
  width: 100%;
  background: rgba(26, 33, 51, 1);
}

.pi-heart {
  cursor: pointer;
}

.show-more-popular-btn {
  position: sticky;
  margin-top: 150px;
  bottom: 50px;
  left: 36%;
  width: fit-content;
}

.brand-name-mansory-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.text-subitem {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.mansory-item-text__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px;
  gap: 12px;
  color: rgba(61, 61, 61, 1);
}

.image-mansory {
  width: 100%;
  height: 100%;
  border-radius: 32px;
}

.top-content-filters {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  gap: 16px;
}

.top-content-upper-filters__wrapper {
  display: flex;
  gap: 8px;
}

.top-content-selected-filters__wrapper {
  display: flex;
  gap: 8px;
}

.top-content-filters__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border: 1px solid rgba(115, 99, 84, 1);
  border-radius: 40px;
  padding: 16px 20px 16px 12px;
  color: rgba(115, 99, 84, 1);
}

.top-content-selected-filters__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: 1px solid rgba(147, 120, 101, 0.1);
  border-radius: 40px;
  padding: 4px 12px;
  color: rgba(115, 99, 84, 1);
  background: rgba(147, 120, 101, 0.1);
  gap: 8px;
}

.top-content__text {
  font-size: 52px;
  line-height: 60px;
  max-width: 1360px;
}

.recommendation_content_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  width: 100%;
  background: rgba(245, 245, 247, 1);
  gap: 40px;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}

.mansory-wall-wrapper {
  max-height: 4200px;
  width: 90%;
  position: relative;
}

.mansory-item__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 2px 3px 0px rgba(26, 33, 51, 0.05);
  border: 1px solid transparent;
  border-radius: 40px;
  background: white;
  overflow: hidden;
}

.mansory-item-img__wrapper {
  width: 100%;
  border-radius: 32px;
}

.recommendation_top_content__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
}
</style>
