<script setup lang="ts">
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import Tab from 'primevue/tab'
import TabPanels from 'primevue/tabpanels'
import TabPanel from 'primevue/tabpanel'
import InternationalisationContentItem from './InternationalisationContentItem.vue'
import Listbox from 'primevue/listbox';

import { ref } from 'vue'

const selectedCountry = ref();
const tabs = ref([
  {
    title: 'ЯЗЫК',
    content: [
      {
        title: 'Русский',
        subtitle: 'Русский',
        icon: 'RU'
      },
      {
        title: 'English',
        subtitle: 'Английский',
        icon: 'GB'
      },
      {
        title: 'Українська',
        subtitle: 'Украинский',
        icon: 'UA'
      },
      {
        title: 'Беларуская',
        subtitle: 'Белорусский',
        icon: 'BY'
      },
      {
        title: 'Polski',
        subtitle: 'Польский',
        icon: 'PL'
      },
      {
        title: 'Deutch',
        subtitle: 'Немецкий',
        icon: 'DE',
      }
    ],
    value: '0'
  },
  {
    title: 'ВАЛЮТА',
    content: [
      {
        title: 'BYN',
        subtitle: 'Белорусский рубль',
        icon: 'BY'
      },
      {
        title: 'USD',
        subtitle: 'Доллар США',
        icon: 'US'
      },
      {
        title: 'EUR',
        subtitle: 'Евро',
        icon: 'EU',
      },
      {
        title: 'RUB',
        subtitle: 'Российский рубль',
        icon: 'RU'
      },
      {
        title: 'PLN',
        subtitle: 'Польский злоты',
        icon: 'PL'
      },
      {
        title: 'UAH',
        subtitle: 'Украинская гривна',
        icon: 'UA'
      }
    ],
    value: '1'
  }
])

const tabTheme = ref({
  tab: {
    activeColor: 'rgba(48, 48, 48, 1)',
    color: 'rgba(145, 145, 145, 1)',
    borderColor: 'rgba(215, 217, 219, 1)',
    padding: '12px 16px',
    activeBorderColor: 'rgba(48, 48, 48, 1)'
  },
  activeBar: {
    background: 'rgba(48, 48, 48, 1)'
  }
})
</script>

<template>
  <Tabs
    value="0"
    :pt="{
      panelContainer: {
        style: {
          padding: '0'
        }
      }
    }"
    :dt="tabTheme"
  >
    <TabList
      :pt="{
        tabList: {
          style: {
            width: '100%',
            background: 'transparent'
          }
        }
      }"
    >
      <Tab
        v-for="tab in tabs"
        :key="tab.title"
        :value="tab.value"
        :pt="{
          root: {
            style: {
              width: '50%'
            }
          }
        }"
      >
        {{ tab.title }}
      </Tab>
    </TabList>
    <TabPanels
      :pt="{
        root: {
          style: {
            background: 'transparent',
            padding:'20px 0px 0px 0px'
          }
        }
      }"
    >
      <TabPanel
        v-for="tabContent in tabs"
        :value="tabContent.value"
      >
        <div class="language-list-wrapper">
          <Listbox v-model="selectedCountry" :options="tabContent.content" checkmark  optionLabel="name" :pt="{
              root:{
                style:{
                  background: 'transparent',
                  border:'none',
                }
              },
              listContainer:{
                style:{
                  padding:'0',
                  maxHeight:'350px',
                }
              },
              list:{
                style:{
                  padding:'0'
                }
              },
              option:{
                style:{
                  flexDirection:'row-reverse',
                  padding:'0',
                  borderBottom:'1px solid rgba(215, 217, 219, 1)'
                }
              }

          }">
            <template #option="slotProps">
              <div class="language-wrapper-item">
                <div class="language-icon">
                  <img :alt="slotProps.option.title" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`flag flag-${slotProps.option.icon.toLowerCase()} mr-2`" style="width: 18px" />
                </div>
                <div class="language-text">
                  <span class="language-title">{{ slotProps.option.title }}</span>
                  <span class="language-subtitle">{{ slotProps.option.subtitle }}</span>
                </div>
              </div>
            </template>
          </Listbox>
        </div>
      </TabPanel>
    </TabPanels>
  </Tabs>
</template>

<style scoped>
.language-list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 304px;
  max-height: 418px;
  border-radius: 8px;
  gap:8px;
}

:deep(.p-listbox-option:last-child) {
  border-bottom: none !important;
}

.language-wrapper-item {
  display: flex;
  width: 100%;
  max-height: 62px;
  padding-bottom: 4px;
}

.language-text {
  display: flex;
  flex-direction: column;
  width: 200px;
  max-height: 46px;
  gap: 4px;
}

.language-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.language-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(61, 61, 61, 1);
}

.language-confirm-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
