import type { payloadFilter } from '@/types/searchItemsTypes'


export function transformFilters(data: any[]) {
  const filters: payloadFilter[] = [];

  data.forEach(item => {
    const filterType = item.options.label;
    const values = item.options.values || [];

    if (item.type === 'GRAPH_SELECT') {
      filters.push({
        filter_type: filterType,
        values: { lte: item.options.currentMax, gte: item.options.currentMin }
      });
    } else {
      const selectedValues = values
        .filter((v: any) => v.isSelected)
        .map((v: any) => v.name);
      filters.push({
        filter_type: filterType,
        values: selectedValues
      });
    }
  });

  return  filters.filter((v) => (v.values.length || v.values.lte )) ;
}
