<script setup lang="ts">
import { computed, ref } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Message from 'primevue/message'
import Password from 'primevue/password'
import FloatLabel from 'primevue/floatlabel'
import { useAuthStore } from '@/stores/user'
import { useAuthModal } from '@/stores/authModal'
import Divider from 'primevue/divider'


const emits = defineEmits(['closeModal'])

const email = ref('')
const emailError = ref('')

const valuePassword = ref('')
const passwordError = ref('')

const disableSubmit = computed(() => {
  return email.value === '' || valuePassword.value === '' || !!emailError.value.length || !!passwordError.value.length;

})

function createAccountHandler() {
  useAuthModal().showAuthModal('signUp')
}

const validatePassword = () => {
  passwordError.value = valuePassword.value.length >= 8 ? '' : 'Введите корректный пароль. (Больше 8 симоволов)'
}

const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (!emailPattern.test(email.value)) {
    emailError.value = 'Введите корректный email адрес.'
  } else {
    emailError.value = ''
  }
}

function forgotPasswordHandler() {
  useAuthModal().showAuthModal('resetPassword')
}

const handleLogin = async () => {
  await useAuthStore()
    .auth({ email: value.value, password: valuePassword.value })
    .then((res) => {
      emits('closeModal')
    })
}

const handleThirdPartyLogin = async (payload) => {
  await useAuthStore().thirdPartyAuth(payload)
}
</script>

<template>
  <div class="sign-in__content">
    <div class="sign-in__content__wrapper">
      <div class="sign-in__inputs">
        <div>
        <FloatLabel>
          <InputText
            v-model="email"
            :invalid="!!emailError"
            class="email"
            aria-label="email"
            @input="validateEmail"
            @blur="validateEmail"
            :pt="{
              root: {
                style: {
                  width: '100%',
                  borderRadius: '8px'
                }
              }
            }"
          />
          <label for="email">Введите адрес электронной почты</label>
        </FloatLabel>
          <Message
            v-if="!!emailError"
            severity="error" size="small"
                   :pt="{
                     root:{
                       style:{
                         outline: 'none',
                       },
                     },
                     content:{
                       style:{
                         padding:'0',
                         background:'transparent',
                       }
                     },
                     text:{
                       style:{
                         paddingLeft:'16px',
                         fontSize:'12px',
                       },
                     }
                   }"
          >{{ emailError }}
          </Message>
        </div>
        <div>
        <FloatLabel>
          <Password
            v-model="valuePassword"
            @input="validatePassword"
            @blur="validatePassword"
            :invalid="!!passwordError"
            :feedback="false"
            class="password"
            type="password"
            aria-label="password"
            toggle-mask
            :pt="{
              root: {
                style: {
                  width: '100%',
                  borderRadius: '8px'
                }
              },

              pcInput: {
                root: {
                  style: {
                    width: '100%',
                    borderRadius: '8px'
                  }
                }
              }
            }"
          />
          <label for="password">Введите пароль</label>
        </FloatLabel>
          <Message
            v-if="!!passwordError"
            severity="error" size="small"
            :pt="{
                     root:{
                       style:{
                         outline: 'none',
                       },
                     },
                     content:{
                       style:{
                         padding:'0',
                         background:'transparent',
                       }
                     },
                     text:{
                       style:{
                         paddingLeft:'16px',
                         fontSize:'12px',
                       },
                     }
                   }"
          >{{passwordError}}
          </Message>
        </div>
      </div>
      <div class="forgot__password">
        <Button
          class="forgot__password"
          label="Забыли пароль ?"
          text
          :pt="{
            root: {
              style: {
                padding: '0'
              }
            }
          }"
          @click="forgotPasswordHandler"
        />
      </div>
    </div>
    <div class="sign-in__submit_btn__wrapper">
      <Button
        class="sign-in-submit"
        label="Войти"
        :disabled="disableSubmit"
        :pt="{
          root: {
            style: {
              width: '100%',
              height: '48px'
            }
          }
        }"
        @click="handleLogin"
      />
    </div>
  </div>
</template>
<style scoped>
.sign-in__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.sign-in__content__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding: 20px 0;
  gap: 8px;
}

.sign-in__inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.sign-in__submit_btn__wrapper {
  width: 100%;
}
</style>
