<script setup lang="ts">
import Divider from 'primevue/divider'
import { ref } from 'vue'
import FooterLogo from '@/components/UI/CustomIconsAndImages/FooterLogo.vue'
import TelegramIcon from '@/components/UI/CustomIconsAndImages/TelegramIcon.vue'
import VKIcon from '@/components/UI/CustomIconsAndImages/VKIcon.vue'
import InstagramIcon from '@/components/UI/CustomIconsAndImages/InstagramIcon.vue'
import TikTokIcon from '@/components/UI/CustomIconsAndImages/TikTokIcon.vue'

//TODO вынести текст в константы, иконки вынести в компоненты отдельные посмотреть повтораяющиеся стили

const links = ref({
  'О НАС': [
    'О компании',
    'Контактная информация',
    'Гарантии',
    'Политика конфиденциальности',
    'Условия обслуживания'
  ],
  ПОЛЬЗОВАТЕЛЯМ: [
    'FAQ (часто задаваемые вопросы)',
    'Помощь',
    'Рекомендации по ...',
    'Политика обработки cookies'
  ],
  'ДЛЯ БИЗНЕСА': ['Для бизнеса', 'Часы работы службы поддержки', 'Форма обратной связи']
})

const bottomText = ref({
  year: '2024-2024',
  companyName: 'ООО "Селектио"'
})
</script>

<template>
  <div class="footer__wrapper">
    <div class="footer-top__wrapper">
      <div class="footer-left-column">
        <div class="footer-logo-wrapper">
          <footer-logo />
        </div>
        <span class="logo-text">ИЩИ. ПОКУПАЙ. ВДОХНОВЛЯЙ.</span>
      </div>
      <div class="footer-right-column">
        <ul
          v-for="(listOfLinks, key) in links"
          class="list-of-links"
        >
          <li class="list-title">
            {{ key }}
          </li>
          <li class="link" v-for="link in listOfLinks">
            {{ link }}
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom__wrapper">
      <Divider />
      <div class="footer-icons__wrapper">
        <div class="pointer">
          <telegram-icon />
        </div>
        <div class="pointer">
          <VKIcon />
        </div>
        <div class="pointer">
          <instagram-icon />
        </div>
        <div class="pointer">
          <TikTokIcon />
        </div>
      </div>
      <p class="bottom-text">
        <span>{{ bottomText.year }}</span>
        <span>{{ bottomText.companyName }}</span>
      </p>
    </div>
  </div>
</template>

<style scoped>
.pointer {
  cursor: pointer;
}

.footer__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: rgba(36, 49, 74, 1);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}

.footer-top__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 140px 100px 40px 100px;
  width: 100%;
}

.footer-bottom__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.footer-left-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.logo-text {
  display: inline-block;
  width: 100%;
  color: rgba(225, 213, 191, 1);
  font-size: 16px;
  line-height: 21px;
}

.footer-right-column {
  display: flex;
}

.list-of-links {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 24px;
  font-size: 16px;
  line-height: 21px;
  color: rgba(215, 217, 219, 1);
}

.list-title {
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
  color: rgba(225, 213, 191, 1);
}

.footer-icons__wrapper {
  display: flex;
  gap: 12px;
  margin-top: 52px;
}

.bottom-text {
  display: flex;
  flex-direction: column;
  color: rgba(145, 145, 145, 1);
  margin-top: 40px;
  padding-bottom: 26px;
}

.link {
  display: block;
  width: fit-content;
  position: relative;
  padding: 0.2em 0;
  cursor: pointer;
}


.link::after {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: rgba(255, 255, 255, 1);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}


.link:after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

.link:hover::after,
.link:focus::after{
  transform: scale(1);
}
</style>
