<script setup lang="ts">
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import Tab from 'primevue/tab'
import { ref } from 'vue'

const items = ref([
  { route: '/?audience=woman', label: 'Для женщин' },
  { route: '/?audience=man', label: 'Для мужчин' },
  { route: '/?audience=kids', label: 'Для детей' },
  { route: '/?audience=home', label: 'Для дома' }
])

const tabTheme = ref({
  tab: {
    background: 'transparent',
    hoverBackground: 'rgba(255, 255, 255, 0.2)',
    activeBackground: 'rgba(245, 245, 247, 1)',
    activeColor: 'rgba(61, 61, 61, 1)',
    color: 'rgba(215, 217, 219, 1)',
    hoverColor: 'rgba(255, 255, 255, 1)',
    borderWidth: '0',
    padding: '0',
    activeBorderColor: 'transparent'
  },
  activeBar: {
    height: '100%',
    background: 'rgba(245, 245, 247, 1)'
  }
})
</script>

<template>
  <Tabs
    value="/?audience=woman"
    :pt="{
      root: {
        style: {
          borderRadius: '40px',
          padding: '4px',
          background: 'rgba(245, 245, 247, 0.1)'
        }
      }
    }"
    :dt="tabTheme"
  >
    <TabList
      :pt="{
        tabList: {
          style: {
            background: 'transparent',
            display: 'flex',
            gap: '24px',
            border: 'none'
          },
          activeBar: {
            border: 'none',
            borderRadius: '40px'
          }
        }
      }"
    >
      <Tab
        v-for="tab in items"
        :key="tab.label"
        :value="tab.route"
        :pt="{
          root: {
            style: {
              fontSize: '14px',
              borderRadius: '40px'
            }
          }
        }"
      >
        <router-link
          v-if="tab.route"
          v-slot="{ href, navigate }"
          :to="tab.route"
          custom
        >
          <a
            :href="href"
            class="tab-link-item"
            @click="navigate"
          >
            <span>{{ tab.label }}</span>
          </a>
        </router-link>
      </Tab>
    </TabList>
  </Tabs>
</template>

<style scoped>
.tab-link-item {
  display: block;
  text-decoration: none;
  color: unset;
  padding: 12px 16px;
}

:deep(.p-tablist-active-bar) {
  z-index: -1;
  border-radius: 40px;
}
</style>
