interface Value {
  is_available: boolean;
  name: string;
  quantity: number;
  id: number;
  color?: string;
  isSelected?: boolean;
}

interface Options {
  label: string;
  values: Value[];
}

interface Item {
  type: string;
  options: Options;
}

interface Obj1 {
  [key: string]: string[];
}

export function updateObjects(obj1: Obj1, obj2: Item[]): Item[] {
  obj2.forEach(item => {
    const label = item.options.label;

    if (label in obj1) {
      const obj1Values = obj1[label];
      if (item.type === 'GRAPH_SELECT') {
        item.options.currentMin = obj1[item.options.label][0];
        item.options.currentMax = obj1[item.options.label][1];
      }

      if (item.type === 'MULTI_SELECT' || item.type === 'COLOR_SELECT') {
        item.options.values.forEach(value => {
          if (obj1Values.includes(value.name)) {
            value.isSelected = true;
          }
        });
      }
    }
  });

  return obj2;
}