export async function sendMessage(token, message) {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/websocket/test_send_massage/${encodeURIComponent(message)}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (response.ok) {
      console.log('ok')
    } else {
      console.error('Failed to send message.')
    }
  } catch (error) {
    console.error('Error sending message:', error)
  }
}
