<script setup lang="ts"></script>

<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="47"
      height="47"
      rx="23.5"
      stroke="#E1D5BF"
    />
    <path
      d="M32.8445 16.5769L32.8578 16.5718L32.8707 16.5659C33.1343 16.4467 33.2999 16.5101 33.3774 16.585C33.4615 16.6664 33.5906 16.9056 33.4615 17.4234L33.4593 17.432L33.4575 17.4406L30.4385 31.6686L30.4381 31.6703C30.3465 32.1096 30.1986 32.2314 30.1272 32.2636C30.0547 32.2962 29.8768 32.32 29.5286 32.1345L24.9577 28.7582L24.6163 28.506L24.312 28.8019L22.1024 30.9506C22.1022 30.9508 22.102 30.9509 22.1019 30.9511C22.0956 30.9572 22.0894 30.9631 22.0834 30.969L22.328 27.3128L30.7075 19.7503C30.7081 19.7498 30.7086 19.7493 30.7092 19.7489C30.8309 19.6405 31.0026 19.4491 31.0126 19.1825C31.0183 19.0309 30.9686 18.8846 30.871 18.7684C30.7801 18.6601 30.6679 18.6011 30.5755 18.5693C30.3964 18.5079 30.2062 18.5175 30.0483 18.5503C29.8808 18.585 29.7059 18.6543 29.5367 18.7572L29.5367 18.7572L29.5298 18.7615L19.1931 25.2833L14.8615 23.9321L14.8615 23.9321L14.8593 23.9314C14.6781 23.8758 14.5746 23.8153 14.5232 23.7733C14.5361 23.7576 14.5551 23.7375 14.5827 23.7131C14.6798 23.6272 14.8498 23.5206 15.1179 23.4126L32.8445 16.5769Z"
      fill="#E1D5BF"
      stroke="#E1D5BF"
    />
  </svg>
</template>

<style scoped></style>
