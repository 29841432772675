<script setup lang="ts">
import { computed } from 'vue'
import { useAuthStore } from '@/stores/user'
const auth = useAuthStore()

const userEmail = computed(() => auth.userInfo.tempEmailFormPasswordRestore)
</script>

<template>
  <div class="sign-in__content">
    <span class="sign-in__title">Проверь свою почту</span>
    <span>Подтвердите регистрацию на почте на
      <a :href="`mailto:${userEmail}`">{{ userEmail }} </a></span>
    <div class="mail-image">
      <svg
        width="128"
        height="129"
        viewBox="0 0 128 129"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M78.2222 117.423C78.2222 125.278 49.7778 125.278 49.7778 117.423V81.8677C49.7778 78.0958 51.2762 74.4783 53.9434 71.8111C56.6106 69.1439 60.228 67.6455 64 67.6455C67.772 67.6455 71.3894 69.1439 74.0566 71.8111C76.7238 74.4783 78.2222 78.0958 78.2222 81.8677V117.423Z"
          fill="#937865"
        />
        <path
          d="M35.5556 10.7566C28.0116 10.7566 20.7767 13.7534 15.4423 19.0878C10.1079 24.4221 7.11111 31.6571 7.11111 39.201V81.8677H64V39.201C64 31.6571 61.0032 24.4221 55.6688 19.0878C50.3345 13.7534 43.0995 10.7566 35.5556 10.7566Z"
          fill="#1A2133"
        />
        <path
          d="M71.1111 66.7955C71.1111 71.1902 67.3245 74.7564 62.9582 74.7564H8.15289C3.78311 74.7564 0 71.1902 0 66.7955V29.384C0 24.9893 3.78311 21.4231 8.15289 21.4231H62.9582C67.3245 21.4231 71.1111 24.9893 71.1111 29.384V66.7955Z"
          fill="#D7D9DB"
        />
        <path
          d="M71.1111 29.384C71.1111 24.9893 67.3245 21.4231 62.9582 21.4231H8.15289C3.78311 21.4231 0 24.9893 0 29.384V34.4293L17.3476 51.8658L0.352 68.9538C0.750222 70.2907 1.47556 71.4995 2.47111 72.4489L20.1493 54.6782L28.7076 63.2791C29.5975 64.1749 30.6558 64.8859 31.8216 65.371C32.9875 65.8562 34.2377 66.1059 35.5004 66.1059C36.7632 66.1059 38.0134 65.8562 39.1792 65.371C40.3451 64.8859 41.4034 64.1749 42.2933 63.2791L50.9191 54.632L68.64 72.4489C69.6356 71.496 70.3573 70.2871 70.7591 68.9538L53.7209 51.8231L71.1111 34.3867V29.384Z"
          fill="#A2B8E9"
        />
        <path
          d="M62.9582 21.4231H8.15288C4.00711 21.4231 0.430217 24.6444 0.071106 28.7298L29.9662 58.5858C33.056 61.6933 38.0622 61.5724 41.1484 58.4613L71.0507 28.7867C70.7164 24.6729 67.1182 21.4231 62.9582 21.4231Z"
          fill="#F5F5F7"
        />
        <path
          d="M92.4444 10.7566H35.5556C43.0995 10.7566 50.3345 13.7534 55.6688 19.0878C61.0032 24.4221 64 31.6571 64 39.201V81.8677H120.889V39.201C120.889 31.6571 117.892 24.4221 112.558 19.0878C107.223 13.7534 99.9884 10.7566 92.4444 10.7566Z"
          fill="#A2B8E9"
        />
        <path
          d="M92.4444 3.64551H78.2222C76.3362 3.64551 74.5275 4.39471 73.1939 5.7283C71.8603 7.0619 71.1111 8.87064 71.1111 10.7566V53.4233C71.1111 55.3093 71.8603 57.118 73.1939 58.4516C74.5275 59.7852 76.3362 60.5344 78.2222 60.5344C80.1082 60.5344 81.9169 59.7852 83.2505 58.4516C84.5841 57.118 85.3333 55.3093 85.3333 53.4233V24.9788H92.4444C94.3304 24.9788 96.1392 24.2296 97.4728 22.896C98.8064 21.5625 99.5556 19.7537 99.5556 17.8677V10.7566C99.5556 8.87064 98.8064 7.0619 97.4728 5.7283C96.1392 4.39471 94.3304 3.64551 92.4444 3.64551Z"
          fill="#BA1A1A"
        />
      </svg>
    </div>
  </div>
</template>
<style scoped>
.sign-in__content {
  display: flex;
  border-radius: 40px;
  padding: 0 40px 40px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  text-align: center;
}

.sign-in__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 12px;
}

.mail-image {
  margin-top: 40px;
}
</style>
