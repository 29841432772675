<script setup lang="ts">
import { Autoplay, Controller, FreeMode, Navigation } from 'swiper/modules'
import { onMounted, ref, watch } from 'vue'
import 'swiper/css'
import 'swiper/css/free-mode'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { getPromotedList } from '@/api/recommendations'
import { useRoute } from 'vue-router'

const route = useRoute()

const swiperModules = [FreeMode, Autoplay, Navigation, Controller]

const companies = ref([])

watch(
  route,
  async () => {
    if (route.query.audience) {
      await getPromotedList(30, route.query.audience).then((res) => {
        companies.value = [...res.data.data]
      })
    }
  },
  { deep: true }
)

onMounted(async () => {
  await getPromotedList(30, route.query.audience ? route.query.audience : 'woman').then((res) => {
    companies.value = [...res.data.data]
  })
})
</script>

<template>
  <div class="adv-wrapper">
    <div class="swp-wrp">
      <swiper
        :loop="true"
        :free-mode="true"
        :space-between="20"
        :slides-per-view="'auto'"
        :speed="25000"
        :modules="swiperModules"
        :autoplay="{ disableOnInteraction: false }"
        class="company-carousel__container"
      >
        <swiper-slide
          v-for="(company, index) in companies"
          :key="index"
          class="company-carousel__item"
        >
          <div
            v-if="company?.image_url"
            class="adv-images__wrapper"
          >
            <img
              class="adv-images"
              :src="company.image_url"
            >
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<style scoped>
.adv-wrapper {
  margin-top: 120px;
  width: 100%;
  max-height: 330px;
  overflow: hidden;
}

.adv-images__wrapper {
  border-radius: 40px;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}

.adv-images {
  border-radius: 40px;
  transition: all ease-in-out 0.5s;
}

.adv-images:hover {
  transform: scale(1.2);
  transition: transform ease-in-out 0.5s;
  border-radius: 40px;
}

.company-carousel__item {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 40px;
  text-align: center;
  height: auto;
  max-height: 520px;
  overflow: hidden;
}
</style>

<style>
.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
</style>
