<script setup lang="ts">
import Checkbox from 'primevue/checkbox'
import { computed, ref, watch } from 'vue'
import { useSearch } from '@/stores/search'


export interface MultiSelectData {
  label: string
  values: MultiSelectDataItem[]
}

export interface MultiSelectDataItem {
  is_available: boolean
  name: string
  quantity: number
}

const { name, quantity, isChecked, color, id } = defineProps({
  name: String,
  quantity: Number,
  isChecked: Boolean,
  color: String,
  id: Number,
})


const model = defineModel( )

const search = useSearch()




const ptStyleIfColorSelect = computed(() =>
  color
    ? {
        root: {
          style: {
            width: '32px',
            height: '32px'
          }
        },
        input: {
          style: {
            width: '32px',
            height: '32px'
          }
        },
        box: {
          style: {
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            backgroundColor: `${color}`,
            borderColor: `${color}`
          }
        }
      }
    : {}
)

watch(model,() => {
  search.setCheckBoxValueState(id,model.value)
})

</script>

<template>
  <div class="multiselect-inner-item apnd"
       :class="{
        color_multiselect: color
       }"
  >
    <Checkbox
      v-model="model"
      :pt="ptStyleIfColorSelect"
      :binary="true"
    />
    <span class="multiselect-label">{{ name }}</span>
    <span class="multiselect-count">{{ quantity }}</span>
  </div>
</template>

<style scoped>
.multiselect-inner-item {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 48px;
  padding: 12px;

}

.multiselect-count {
  color: rgba(145, 145, 145, 1);
}

.color_multiselect:hover {
  border-radius: 40px;
  cursor: pointer;
  background: rgba(162, 184, 233, 0.3);
}

</style>
